<template>
  <b-navbar id="default-navbar" :class="navbarClass" :type="navbarType" :variant="navbarVariant">
    <div :class="navbarContainerClass">
      <!-- Toggle sidebar -->
      <button
        v-fmv-toggle.default-drawer
        class="navbar-toggler w-auto mr-16pt d-block rounded-0"
        :class="navbarToggleClass"
        type="button"
        v-if="!isRoutePublicSchlLanding && get(getLoggedInUser, 'role') !== USER_ROLES.STUDENT"
      >
        <md-icon>menu</md-icon>
      </button>

      <slot>
        <navbar-content
          :brand-image="getNavbarBrandLogo || brandImage"
          :navbar-type="navbarType"
          :navbar-brand="getNavbarBrandText"
          :navbar-brand-class="navbarBrandClass"
          :navbar-search="navbarSearch"
          :navbar-full-sample-guest-mode="navbarFullSampleGuestMode"
          :navbar-content-container-class="navbarContentContainerClass || navbarContainerClass"
        />
      </slot>
    </div>
  </b-navbar>
</template>

<script>
import MdIcon from '@/components/Ui/MdIcon.vue';
import NavbarContent from './NavbarContent.vue';

import navbarProps from '@/components/Layout/navbar-props';
import navbarContentProps from '@/components/Layout/navbar-content-props';
import { SCH_PUBLIC_PORTAL_LINKS, USER_ROLES } from '../../common/constants';
import { get } from 'lodash';
import brandImage from '@/assets/images/logos/logo_icon.png';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    NavbarContent,
    MdIcon,
  },
  mixins: [navbarProps, navbarContentProps],
  props: {
    brandImage: {
      type: String,
      default() {
        return brandImage;
      },
    },
  },
  data() {
    return {
      USER_ROLES,
    };
  },
  computed: {
    ...mapGetters('navbarConfig', ['getNavbarBrandLogo', 'getNavbarBrandText']),
    ...mapGetters('auth', ['isLoggedIn', 'getLoggedInUser']),
    isRoutePublicSchlLanding() {
      return SCH_PUBLIC_PORTAL_LINKS.includes(this.$route.name);
    },
  },
  methods: {
    ...mapActions('navbarConfig', ['updateNavbarBrandDetails']),
    get,
  },
  created() {
    if (this.isLoggedIn) {
      this.updateNavbarBrandDetails();
    }
  },
};
</script>
