import axios from 'axios';

const state = {
  signupRequestStatus: false,
};

const getters = {
  getSignupRequestStatus: state => state.signupRequestStatus,
};

const mutations = {
  updateSignupRequestStatus: (state, res) => {
    state.signupRequestStatus = res;
  },
};

const actions = {
  async getAllSignUpRequests(_obj, params) {
    const res = await axios.get(`api/signup_requests/`, { params });
    return res.data;
  },
  async getSignUpRequest(_obj, id) {
    const res = await axios.get(`api/signup_requests/${id}/`);
    return res.data;
  },

  updateSignupRequestStatus({ commit }, res) {
    commit('updateSignupRequestStatus', res);
  },
  async stdSignupReq({ commit }, { data, config }) {
    const res = await axios.post('api/signup_requests/student-signup/', data, config);
    commit('updateSignupRequestStatus', true);
    return res;
  },

  async stdDirectSignup(_obj, { data, config }) {
    const res = await axios.post('api/students/direct-std-signup/', data, config);
    return res;
  },

  async updateSignUpRequestStatus(_obj, { id, data }) {
    return axios.patch(`api/signup_requests/${id}/change-approval-status/`, data);
  },
};

export const signUpRequest = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
