<template>
  <div>
    <ul class="sidebar-menu">
      <template v-for="(item, itemIdx) in localMenu">
        <template v-if="item.children !== undefined && item.children.length">
          <li :key="`smi-collapse-${itemIdx}`" :class="{ open: item.open }" class="sidebar-menu-item">
            <span v-b-toggle="`${item.id}-${itemIdx}`" class="sidebar-menu-button" href="#" @click.prevent>
              <i class="material-icons sidebar-menu-icon sidebar-menu-icon--left">{{ item.icon.id }}</i>
              {{ item.label }}
              <span v-if="badge" class="ml-2 unread-indicator bg-accent"></span>

              <span class="ml-auto sidebar-menu-toggle-icon" />
            </span>
            <b-collapse
              :id="`${item.id}-${itemIdx}`"
              v-model="item.open"
              class="sidebar-submenu sm-indent"
              tag="ul"
              @show="dropDownVisible = true"
              @hide="dropDownVisible = false"
            >
              <li
                v-for="item in item.children"
                :key="item.route.name"
                class="sidebar-menu-item"
                :class="{ active: item.active }"
                @click.prevent="closeSidebar"
              >
                <router-link :to="item.route" class="sidebar-menu-button clickable-item">
                  <span class="sidebar-menu-text">{{ item.label }}</span>
                  <span
                    v-if="item.route.name === 'manage-partnerships' && badge"
                    class="ml-2 unread-indicator bg-accent"
                  ></span>
                </router-link>
              </li>
            </b-collapse>
          </li>
        </template>
        <template v-else>
          <li :key="`smi-collapse-${itemIdx}`" class="sidebar-menu-item" @click.prevent="closeSidebar">
            <router-link :to="item.route" class="sidebar-menu-button clickable-item">
              <i class="material-icons sidebar-menu-icon sidebar-menu-icon--left" :class="{ active: item.active }">{{
                item.icon.id
              }}</i>
              <span class="sidebar-menu-text">{{ item.label }}</span>
            </router-link>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'SidebarMenu',
  props: {
    menu: { type: Array, default: () => [] },
    badge: { type: Boolean, default: false },
  },
  data() {
    return {
      currentRoute: '',
      dropDownVisible: false,
      localMenu: [],
      windowWidth: window.innerWidth,
    };
  },

  watch: {
    $route: {
      deep: true,
      handler(value) {
        if (value) {
          if (this.menu.length) {
            this.localMenu = this.menu.map((item) => {
              const routes = (item.children || []).map((item) => item.route.name);

              const routesNames = routes.map((route) => {
                return this.$router.resolve({
                  name: route,
                }).href;
              });
              return {
                ...item,
                children: (item.children || []).map((child) => {
                  return {
                    ...child,
                    active:
                      child.route.name === this.$route.name ||
                      this.$route.path.startsWith(
                        this.$router.resolve({
                          name: child.route.name,
                        }).href
                      ),
                  };
                }),
                open:
                  routes.includes(this.$route.name) ||
                  !!routesNames.find((route) => this.$route.path.startsWith(route)),
                active:
                  get(item, 'route.name') === this.$route.name ||
                  this.$route.path.startsWith(
                    this.$router.resolve({
                      name: get(item, 'route.name'),
                    }).href
                  ),
              };
            });
          }
        }
      },
    },
  },

  computed: {
    isMobileScreen() {
      return this.windowWidth <= 992;
    },
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    closeSidebar() {
      this.isMobileScreen ? this.$root.$emit('fm::close::drawer', 'default-drawer') : null;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    this.localMenu = this.menu.map((item) => {
      const routes = (item.children || []).map((item) => item.route.name);
      return {
        ...item,
        open: routes.includes(this.$route.name),
        active: routes.includes(this.$route.name),
      };
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
