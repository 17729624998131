import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllAdvisors(_obj, params) {
    const res = await axios.get(`api/advisors/`, { params });
    return res.data;
  },

  async getAdvisor(_obj, id) {
    const res = await axios.get(`api/advisors/${id}/`);
    return res.data;
  },

  async createAdvisor(_obj, data) {
    return axios.post(`api/advisors/`, data);
  },

  async updateAdvisor(_obj, { id, data }) {
    return axios.patch(`api/advisors/${id}/`, data);
  },
  async deleteAdvisor(_obj, id) {
    return axios.delete(`api/advisors/${id}/`);
  },

  async signupAdvisorFromInvite(_obj, data) {
    return axios.post(`api/advisors/invite/`, data);
  },

  async getOrgAdvisorInviteId() {
    return (await axios.get(`api/organizations/advisor-invite-id/`)).data;
  }
};

export const advisors = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
