import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllResumes(_obj, params) {
    const res = await axios.get(`api/resumes/`, { params });
    return res.data;
  },
  async getResumeCounts(_obj, data) {
    return (await axios.post(`api/resumes/resumes-count/`, data)).data.data;
  }
};

export const resume = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
