import Vue from 'vue';

import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

import 'bootstrap-vue/dist/bootstrap-vue.css';

import {
  BIcon,
  BIconFacebook,
  BIconInstagram,
  BIconTwitter,
  BIconYoutube,
  BIconLinkedin,
  BIconMap
} from 'bootstrap-vue';

Vue.component('BIcon', BIcon);
Vue.component('BIconFacebook', BIconFacebook);
Vue.component('BIconInstagram', BIconInstagram);
Vue.component('BIconTwitter', BIconTwitter);
Vue.component('BIconYoutube', BIconYoutube);
Vue.component('BIconLinkedin', BIconLinkedin);
Vue.component('BIconMap', BIconMap);
