import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllGpts(_obj, params) {
    const res = await axios.get(`api/gpts/`, { params });
    return res.data;
  },

  async getGpt(_obj, id) {
    const res = await axios.get(`api/gpts/${id}/`);
    return res.data;
  },
  async updateGpt(_obj, { id, data }) {
    return axios.patch(`api/gpts/${id}/`, data);
  },

  async createGpt(_obj, data) {
    const res = await axios.post(`api/gpts/`, data);
    return res.data;
  },

  async deleteGpt(_obj, id) {
    return axios.delete(`api/gpts/${id}/`);
  },
  async updateGptStatus(_obj, { id, data }) {
    return axios.patch(`api/gpts/${id}/change-status/`, data);
  },
  async getGptUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/gpts/upload-url/`, data)).data.data;
  }
};

export const gpts = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
