const authRoutes = ({ routes, isLoggedIn }) => {
  let authRoutes = [];
  if (isLoggedIn) {
    authRoutes.push({
      label: 'Logout',
      route: routes.logout
    });
  } else {
    authRoutes = [
      { label: 'Login', route: routes.login },
      { label: 'Sign Up', route: routes.signup },
      { label: 'Forgot Password', route: routes.forgotPassword }
    ];
  }
  return authRoutes;
};

const studentRoutes = ({ routes }) => [
  {
    label: 'Home',
    route: routes.home,
    exact: true,
    icon: {
      type: 'md-icon',
      id: 'home'
    }
  },
  {
    label: 'Jobs',
    route: routes.jobs,
    icon: {
      type: 'md-icon',
      id: 'cases'
    }
  },
  {
    label: 'Dashboard',
    route: routes.studentDashboard,
    icon: {
      type: 'md-icon',
      id: 'account_box'
    }
  }
];

const instructorRoutes = ({ routes }) => [
  {
    label: 'Dashboard',
    route: routes.instructorDashboard,
    icon: {
      type: 'md-icon',
      id: 'trending_up'
    }
  },
  {
    label: 'Students',
    route: routes.students,
    icon: {
      type: 'md-icon',
      id: 'school'
    }
  },
  {
    label: 'Events',
    route: routes.events,
    icon: {
      type: 'md-icon',
      id: 'event_note'
    }
  }
];

const accountRoutes = ({ routes }) => [
  { label: 'Change Password', route: routes.changePassword },
  { label: 'Edit Account', route: routes.editAccount },
  { label: 'Profile & Privacy', route: routes.editAccountProfile }
];

const communityRoutes = ({ routes }) => [
  { label: 'Student Profile', route: routes.studentProfile },
  { label: 'Instructor Profile', route: routes.instructorProfile }
];

// eslint-disable-next-line no-unused-vars
const studentMenu = (...args) => {
  return {
    id: 'studentMenu',
    label: 'Student',
    icon: {
      type: 'md-icon',
      id: 'account_box'
    },
    children: studentRoutes(...args)
  };
};

// eslint-disable-next-line no-unused-vars
const instructorMenu = (...args) => {
  return {
    id: 'instructorMenu',
    label: 'Instructor',
    icon: {
      type: 'md-icon',
      id: 'school'
    },
    children: instructorRoutes(...args)
  };
};

// eslint-disable-next-line no-unused-vars
const communityMenu = (...args) => {
  return {
    id: 'communityMenu',
    label: 'Community',
    icon: {
      type: 'md-icon',
      id: 'people_outline'
    },
    children: communityRoutes(...args)
  };
};

// eslint-disable-next-line no-unused-vars
const authMenu = (...args) => {
  return {
    id: 'auth',
    label: 'Auth',
    icon: {
      type: 'md-icon',
      id: 'lock_open'
    },
    children: authRoutes(...args)
  };
};

// eslint-disable-next-line no-unused-vars
const accountMenu = (...args) => {
  return {
    id: 'account',
    label: 'Account',
    icon: {
      type: 'md-icon',
      id: 'person_outline'
    },
    children: accountRoutes(...args)
  };
};

const getters = {
  layoutMenu: (state, getters, rootState, rootGetters) => {
    const routes = rootGetters['settings/routes'];
    // const isLoggedIn = !!rootState.auth.user;

    return {
      // home: { label: 'Home', route: routes.home, exact: true, icon: { type: 'md-icon', id: 'home' } },
      // auth: authRoutes({routes, isLoggedIn}),
      student: studentRoutes({ routes }),
      instructor: instructorRoutes({ routes })

      // apps: [
      //   // studentMenu({routes}),
      //   // instructorMenu({routes}),
      //   authMenu({ routes, isLoggedIn }),
      //   accountMenu({ routes }),
      //   communityMenu({ routes })
      // ]

      // components: [
      //   {
      //     id: 'appComponents',
      //     label: 'Application',
      //     icon: {
      //       type: 'md-icon',
      //       id: 'tune'
      //     },
      //     children: [
      //       { label: 'App Layout', route: routes.uiAppLayout },
      //       { label: 'Fixed Layout', route: routes.uiFixedLayout }
      //     ]
      //   },
      // ]
    };
  }
};

const state = () => ({});

export const sidebarMenuConfig = {
  namespaced: true,
  state,
  getters
};
