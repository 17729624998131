var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.footerClass},[_c('div',{staticClass:"page-section d-flex flex-column",class:_vm.containerClass},[_vm._t("default",function(){return [(_vm.localFooterLogo)?_c('p',{staticClass:"brand mb-24pt",class:{
          'text-white-70': _vm.isDark,
          'text-70': !_vm.isDark,
        }},[_c('img',{staticClass:"brand-icon",attrs:{"src":_vm.localFooterLogo,"width":"30","alt":_vm.footerBrand}}),_vm._v(" "+_vm._s(_vm.footerBrand)+" ")]):_vm._e(),(_vm.localDescriptionText || _vm.$slots['description-text'])?_c('p',{staticClass:"measure-lead-max small mr-8pt",class:{
          'text-white-50': _vm.isDark,
          'text-50': !_vm.isDark,
        }},[_vm._t("description-text",function(){return [_vm._v(" "+_vm._s(_vm.localDescriptionText)+" ")]})],2):_vm._e(),_vm._t("links",function(){return [_c('p',{staticClass:"mb-8pt d-flex"},[_c('a',{staticClass:"text-underline mr-8pt small",class:{
              'text-white-70': _vm.isDark,
              'text-70': !_vm.isDark,
            },attrs:{"href":""}},[_vm._v("Terms")]),_c('a',{staticClass:"text-underline small",class:{
              'text-white-70': _vm.isDark,
              'text-70': !_vm.isDark,
            },attrs:{"href":""}},[_vm._v("Privacy policy")])])]}),(_vm.localCopyrightText || _vm.$slots['copyright-text'])?_c('p',{staticClass:"small mt-n1 mb-0",class:{
          'text-white-50': _vm.isDark,
          'text-50': !_vm.isDark,
        }},[_vm._t("copyright-text",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.localCopyrightText)}})]})],2):_vm._e()]},null,_vm.slotProps)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }