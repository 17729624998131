<template>
  <b-nav-item-dropdown
    variant="flush"
    no-caret
    right
    class="notification-scroll dropdown-notifications dropdown-xs-down-full d-sm-flex"
  >
    <template slot="button-content">
      <md-icon :class="iconClass">{{ icon }}</md-icon>
      <b-badge v-if="badge && unreadCount" pill variant="accent badge-notifications">
        {{ unreadCount }}
      </b-badge>
    </template>

    <div class="position-relative" style="height: 400px; overflow-y: auto">
      <div class="mt-3 ml-4 mr-3 d-flex justify-content-between">
        <h6>Notifications</h6>
        <a href="#" @click.prevent="markAllNotificationsRead">
          <md-icon :class="iconClass" v-b-popover.hover.top="'Mark All Read'">{{ markAllReadIcon }}</md-icon>
        </a>
      </div>

      <b-list-group flush>
        <notification-list-item
          v-for="(notification, index, id) in allNotifications"
          :key="index"
          :notification="notification"
          :class="!notification.is_read ? 'unread' : ''"
          :id="id"
          action
          @notificationRead="notificationReadAction"
        />

        <!-- <b-list-group-item>
          <b-btn
            variant="light"
            size="sm"
            :to="routeTo(routeMessages || routes.messages)"
            active-class="fake-active-class"
            exact-active-class="fake-exact-active-class"
            v-text="$t('view_all', { title: $t(title) })"
          />
        </b-list-group-item> -->
      </b-list-group>
      <infinite-loading v-if="totalNotifications !== 0" @infinite="infiniteLoadHandler">
        <div slot="spinner">
          <div class="text-center">
            <i class="fas fa-circle-notch fa-spin" />
          </div>
        </div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <p class="d-flex justify-content-center mt-5" v-if="!totalNotifications">No Notifications</p>
    </div>
  </b-nav-item-dropdown>
</template>

<i18n>
  {
    "en": {
      "view_all": "View {title}"
    },
    "ro": {
      "view_all": "Vezi {title}",
      "Messages": "Mesaje"
    }
  }
</i18n>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import routeToMixin from '@/mixins/route-to';
import NotificationListItem from './NotificationListItem.vue';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLE_TYPES, COMMENT_TYPE } from '../../common/constants';
export default {
  components: {
    NotificationListItem,
    InfiniteLoading
  },
  mixins: [routeToMixin],
  props: {
    badge: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'notifications_none'
    },
    iconClass: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default() {
        return 'navbarMsgs.notifications';
      }
    },
    routeMessages: {
      type: [String, Object]
    }
  },
  data() {
    return {
      allNotifications: [],
      pageNum: 1,
      pageSize: 20,
      isLoadingMore: false,
      areNotificationsLoading: false,
      connection: null,
      unreadCount: 0,
      totalNotifications: 0,
      showNotification: false,
      markAllReadIcon: 'mail_outline',
      COMMENT_TYPE
    };
  },
  methods: {
    ...mapActions('notifications', ['getAllNotifications', 'updateNotification', 'markAllRead']),

    // Notification click handlers.
    notificationReadAction(notification) {
      return {
        emp_scl_partnership_requested: this.partnershipRequestHandler,
        emp_scl_partnership_accepted: this.partnershipRequestHandler,
        testimonial_posted: this.testimonialRequestHandler,
        signup_request_posted: this.signUpRequestHandler,
        partnership_rec_fee_changed: this.recurringFeeChangeHandler,
        partnership_ref_fee_changed: this.referalFeeChangeHandler,
        successfully_hired: this.resumeWorkExperienceHandler,
        token_purchased: this.tokensPurchaseHandler,
        std_profile_unlocked: this.unlockProfileHandler,
        //   org_std_profile_unlocked: this.unlockOrgProfileHandler,
        status_approved: this.statusApproveHandler,
        user_commented: this.commentHandler,
        user_replied: this.replyHandler,
        feedback_posted: this.feedbackHandler,
        feedback_replied: this.feedbackReplyHandler,
        post_liked: this.likeHandler,
        school_credited: this.schoolStdUnlockCreditHandler,
        org_credits_deduct: this.creditsDeductHandler,
        org_token_deduct: this.tokenDeductHandler,
        signup_org_employer: this.signupOrgEmployer,
        app_feature_update: this.appFeatureUpdate,
        new_signup_request: this.newSignUpRequestHandler,
        simulation_submission: this.simulationSubmissionHandler
      }[notification.notification_type](notification);
    },
    async likeHandler(notification) {
      this.$router.push({
        name: 'student-skill-details',
        params: { id: notification.extra.like_on_id }
      });
    },
    creditsDeductHandler() {},
    tokenDeductHandler() {
      this.$router.push({
        name: 'manage-credits-history'
      });
    },
    async statusApproveHandler(notification) {
      this.$router.push({
        name: 'student-skill-details',
        params: {
          id: notification.extra.skill_id
        }
      });
    },
    newSignUpRequestHandler(notification) {
      this.$router.push({
        name: 'interested-school-profile',
        params: { id: notification.object_id }
      });
    },
    simulationSubmissionHandler(notification) {
      this.$router.push({
        name: 'simulations-submission-profile',
        params: { id: notification.extra.call_log_id }
      });
    },
    async feedbackHandler(notification) {
      this.$router.push({
        name: 'student-skill-details',
        params: {
          feedbackId: notification.object_id,
          id: notification.extra.skill_id
        }
      });
    },
    async feedbackReplyHandler(notification) {
      this.$router.push({
        name: 'student-skill-details',
        params: {
          feedbackReplyId: notification.object_id,
          id: notification.extra.skill_id
        }
      });
    },
    async commentHandler(notification) {
      this.$router.push({
        name:
          notification.extra.comment_on_type === COMMENT_TYPE.QUESTION
            ? 'student-question-details'
            : 'student-skill-details',
        params: {
          commentId: notification.object_id,
          id: notification.extra.skill_id || notification.extra.commented_object_id
        }
      });
    },
    async replyHandler(notification) {
      this.$router.push({
        name:
          notification.extra.comment_on_type === COMMENT_TYPE.QUESTION
            ? 'student-question-details'
            : 'student-skill-details',
        params: {
          replyId: notification.object_id,
          id: notification.extra.skill_id || notification.extra.commented_object_id
        }
      });
    },
    async unlockProfileHandler(notification) {
      this.$router.push({ name: 'student-employer-profile', params: { id: notification.extra.employer_id } });
    },
    // async unlockOrgProfileHandler(notification) {
    //       this.$router.push({ name: 'student-org-profile', params: { id: notification.extra.org_id } });
    //     },
    async partnershipRequestHandler(notification) {
      if (notification.extra.request_from_type === USER_ROLE_TYPES.SCHOOL) {
        this.$router.push({ name: 'emp-school-profile', params: { id: notification.extra.request_from_id } });
      } else {
        this.$router.push({ name: 'schl-employer-profile', params: { id: notification.extra.request_from_id } });
      }
    },
    async testimonialRequestHandler(notification) {
      this.$router.push({ name: 'show-student-testimonial', params: { id: notification.object_id } });
    },
    async signUpRequestHandler(notification) {
      this.$router.push({ name: 'show-student-sign-up-request', params: { id: notification.object_id } });
    },
    async recurringFeeChangeHandler(notification) {
      this.$router.push({ name: 'emp-school-profile', params: { id: notification.object_id, detailModal: true } });
    },
    async referalFeeChangeHandler(notification) {
      this.$router.push({ name: 'emp-school-profile', params: { id: notification.object_id, detailModal: true } });
    },
    async resumeWorkExperienceHandler(notification) {
      this.$router.push({
        name: 'std-manage-resume',
        params: { hireId: notification.object_id, workExperienceModal: true }
      });
    },
    tokensPurchaseHandler() {
      this.$router.push({ name: 'manage-credits-history' });
    },

    schoolStdUnlockCreditHandler() {
      this.$router.push({ name: 'redeem-rewards' });
    },

    signupOrgEmployer(notification) {
      this.$router.push({ name: 'org-emp-public-profile', params: { id: notification.extra.employer_id } });
    },

    appFeatureUpdate(notification) {
      this.$router.push({ name: 'feature-update-details', params: { id: notification.extra.app_update_id } });
    },

    async markAllNotificationsRead() {
      const response = this.markAllRead();
      this.unreadCount = response.unread_count;
      for (const notification of this.allNotifications) {
        notification.is_read = true;
      }
    },
    async fetchAndSetNotifications(params = {}) {
      this.areNotificationsLoading = true;
      const response = await this.getAllNotifications({
        limit: this.pageSize,
        offset: (this.pageNum - 1) * this.pageSize,
        ...params
      });
      if (response.data.results) {
        this.allNotifications.push(...response.data.results);
        this.totalNotifications = response.data.count;
      }
      this.unreadCount = response.data.unread_count;
      this.areNotificationsLoading = false;
    },

    async loadMore() {
      this.isLoadingMore = true;
      this.pageNum++;
      await this.fetchAndSetNotifications();
      this.isLoadingMore = false;
    },
    async infiniteLoadHandler($state) {
      if (this.allNotifications.length < this.totalNotifications) {
        await this.loadMore();
        $state.loaded();
      } else $state.complete();
    }
  },

  mounted() {
    this.fetchAndSetNotifications();
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getAuthToken'])
  },
  created() {
    this.connection = new WebSocket(
      `${process.env.VUE_APP_BACKEND_WS_URL}/ws/notifications/${this.getLoggedInUser.id}/?token=${this.getAuthToken}`
    );
    this.connection.onmessage = event => {
      const data = JSON.parse(event.data).message;
      if (data.action === 'Send') {
        this.allNotifications.unshift(data.notification);
        this.unreadCount = data.unread_count;
        this.totalNotifications++;
        this.makeToast({ variant: 'info', msg: 'New Notification Received' });
      } else if (data.action === 'Update') {
        let notificationToUpate = this.allNotifications.find(notification => notification.id === data.notification.id);
        notificationToUpate.is_read = data.notification.is_read;
        this.unreadCount = data.unread_count;
      }
    };
    this.connection.onerror = () => {};
  },
  destroyed() {
    if (this.connection) this.connection.close();
  }
};
</script>

<style lang="scss">
.notification-scroll {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
