<template>
  <fmv-sidebar ref="sidebar" :type="sidebarType" :variant="sidebarVariant" @update="scrollIntoView">
    <!-- Search -->
    <div v-if="sidebarSearch" class="d-flex align-items-center navbar-height">
      <b-form class="search-form w-100 mx-16pt pr-0 pl-16pt" :class="sidebarSearchFormClass">
        <b-form-input :placeholder="$t('Search')" type="text" class="pl-0" />
        <b-button variant="none">
          <md-icon>search</md-icon>
        </b-button>
      </b-form>
    </div>
    <!-- // END Search -->

    <span v-if="sidebarBrand" class="sidebar-brand" :class="sidebarBrandClass">
      <b-img :src="brandImage" width="40" class="mb-3" alt="logo" />
      <span v-text="sidebarBrand" />
    </span>

    <slot v-bind:sidebar="{ menu }">
      <template v-if="sidebarMenu">
        <!-- Sidebar routes -->

        <template v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN">
          <!-- <div class="sidebar-heading"></div> -->
          <sidebar-menu :menu="menu.superAdmin" class="mb-24pt" />
        </template>

        <template v-else-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL">
          <ul class="sidebar-menu mb-0">
            <li class="sidebar-menu-item">
              <a @click.prevent="openStdModal" class="sidebar-menu-button" target="_blank"
                ><i class="material-icons sidebar-menu-icon sidebar-menu-icon--left">web</i>
                <span class="sidebar-menu-text">Career Center</span>
              </a>
            </li>
          </ul>
          <sidebar-menu :menu="menu.school" class="" ref="schoolSideMenu" :key="schoolKey" />

          <sidebar-menu :menu="childrenArr" :badge="getPartnershipStatus" />
          <!-- <sidebar-menu :menu="menu.school" class="" ref="schoolSideMenu" /> -->
        </template>

        <template v-else-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.PARTNER">
          <!-- <div class="sidebar-heading"></div> -->
          <sidebar-menu :menu="menu.partner" class="mb-24pt" />
        </template>

        <template v-else-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER">
          <!-- <div class="sidebar-heading"></div> -->
          <sidebar-menu :menu="menu.employer" />
          <div class="sidebar-heading">Home</div>
          <sidebar-menu :menu="menu.employer_home" />
          <div class="sidebar-heading mt-2">CRM</div>
          <sidebar-menu :menu="menu.employer_crm" />
          <div class="sidebar-heading mt-2">Tools</div>
          <sidebar-menu :menu="menu.employer_tools" />
          <div class="sidebar-heading mt-2" v-if="get(getLoggedInUser, 'linked_entity.organization')">Website</div>
          <sidebar-menu :menu="menu.employer_cs_analytics" />
        </template>

        <template v-else-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT">
          <!-- <div class="sidebar-heading"></div> -->
          <sidebar-menu :menu="menu.student" class="mb-24pt mt-4" />
        </template>

        <template v-else-if="get(getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION">
          <div class="sidebar-heading">Home</div>
          <sidebar-menu :menu="menu.org_home" />
          <div class="sidebar-heading mt-2">CRM</div>
          <sidebar-menu :menu="menu.org_crm" />
          <div class="sidebar-heading mt-2">Tools</div>
          <sidebar-menu :menu="menu.org_tools" />
          <div class="sidebar-heading mt-2">Website</div>
          <sidebar-menu :menu="menu.org_cs" />
          <ul class="sidebar-menu" v-if="get(getLoggedInUser, 'linked_entity.organization_site.wbst_domain')">
            <li class="sidebar-menu-item">
              <a
                :href="getClickableLink(get(getLoggedInUser, 'linked_entity.organization_site.wbst_domain'))"
                class="sidebar-menu-button"
                target="_blank"
                ><i class="material-icons sidebar-menu-icon sidebar-menu-icon--left">visibility</i>
                <span class="sidebar-menu-text">View Site</span>
              </a>
            </li>
          </ul>
        </template>

        <template v-else>
          <!-- School's website link for public pages. -->
          <!-- <ul class="sidebar-menu mb-0 mt-4" v-if="get(getCurrentSchool, 'website_url')">
            <li class="sidebar-menu-item">
              <a
                :href="getClickableLink(get(getCurrentSchool, 'website_url'))"
                class="sidebar-menu-button"
                target="_blank"
                ><i class="material-icons sidebar-menu-icon sidebar-menu-icon--left">home</i>
                <span class="sidebar-menu-text">Home</span>
              </a>
            </li>
          </ul> -->

          <sidebar-menu :menu="menu.unauthRoutes" class="mb-24pt mt-4" />
        </template>

        <!-- <template>
          <div class="sidebar-heading"></div>
          <sidebar-menu :menu="i18nMenu.student" class="mb-24pt" />
        </template>

        <template v-if="menu.components && menu.components.length">
          <div class="sidebar-heading">{{ $t('Components') }}</div>
          <sidebar-menu :menu="i18nMenu.components" class="mb-24pt" />
        </template> -->
      </template>
    </slot>
    <std-center-modal :show-modal="showStdModal" @close="hideStdModal" />
  </fmv-sidebar>
</template>

<script>
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { FmvSidebar } from 'fmv-layout';
import routeToMixin from '@/mixins/route-to';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

import { USER_ROLES, USER_ROLE_TYPES, SCH_PAYMENT_PLANS } from '../../common/constants';

import brandImage from '@/assets/images/logos/logo_icon.png';
import StdCenterModal from '../../views/SchoolPortal/StudentCenter/StdCenterModal.vue';
import SidebarMenu from './SidebarMenu.vue';

export default {
  components: {
    FmvSidebar,
    SidebarMenu,
    MdIcon,
    StdCenterModal,
  },
  mixins: [routeToMixin],
  props: {
    sidebarType: {
      type: String,
      default: () => 'dark-pickled-bluewood',
    },
    sidebarVariant: {
      type: String,
      default: () => null,
    },
    sidebarBrand: {
      type: [String, Boolean],
      default: () => 'Brand',
    },
    brandImage: {
      type: String,
      default() {
        return brandImage;
      },
    },
    sidebarBrandClass: {
      type: [Array, String, Object],
      default: null,
    },
    sidebarSearch: {
      type: Boolean,
      default: true,
    },
    sidebarSearchFormClass: {
      type: [Array, String, Object],
      default: () => null,
    },
    sidebarMenu: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      USER_ROLES,
      USER_ROLE_TYPES,
      SCH_PAYMENT_PLANS,
      scrollIntoViewElement: null,
      subdomain: '',
      showStdModal: false,
      dropDownVisible: false,
      arePartnershipPending: false,

      childrenArr: [
        {
          label: 'Partnerships',
          icon: { type: 'md-icon', id: 'business' },
          id: 'partnerships',
          children: [
            {
              label: 'Partnership Profile',
              route: { name: 'partnership-profile' },
              exact: true,
            },
            {
              label: 'Employers',
              route: { name: 'manage-partnerships' },
            },
            {
              label: 'Universities',
              route: { name: 'manage-partnered-universities' },
              exact: true,
            },
            {
              label: 'Workforce Programs',
              route: { name: 'manage-high-school-districts' },
              exact: true,
            },
            {
              label: 'Organizations',
              route: { name: 'manage-school-organizations' },
              exact: true,
            },
          ],
        },
      ],
      isPaid: false,
      schoolKey: 'school',
      // menu: {
      //   default: [
      //     {
      //       label: this.$t('home'),
      //       route: { name: 'home' },
      //       icon: { type: 'md-icon', id: 'home' },
      //     },
      //   ],
      //   superAdmin: [
      //     {
      //       label: this.$t('home'),
      //       route: { name: 'admin-dashboard' },
      //       icon: { type: 'md-icon', id: 'home' },
      //     },
      //     {
      //       label: 'Users',
      //       route: { name: 'manage-users' },
      //       icon: { type: 'md-icon', id: 'person' },
      //     },
      //     {
      //       label: 'Partnerships',
      //       route: { name: 'manage-all-partnerships' },
      //       icon: { type: 'md-icon', id: 'business' },
      //     },
      //     {
      //       label: 'Feature Updates',
      //       route: { name: 'manage-features' },
      //       icon: { type: 'md-icon', id: 'lightbulb' },
      //     },

      //     // {
      //     //   label: this.$t('schoolMsgs.schools'),
      //     //   route: { name: 'manage-schools' },
      //     //   icon: { type: 'md-icon', id: 'account_balance' },
      //     // },
      //     {
      //       label: this.$t('schoolMsgs.schools'),
      //       icon: { type: 'md-icon', id: 'account_balance' },
      //       id: 'schools-admin-account',
      //       children: [
      //         {
      //           label: 'School Accounts',
      //           route: { name: 'manage-schools' },
      //           icon: { type: 'md-icon', id: 'account_balance' },
      //         },
      //         {
      //           label: 'Interested Schools',
      //           route: { name: 'manage-interested-schools' },
      //           icon: { type: 'md-icon', id: 'account_balance' },
      //         },
      //       ],
      //     },
      //     {
      //       label: this.$t('studentMsgs.students'),
      //       route: { name: 'manage-students' },
      //       icon: { type: 'md-icon', id: 'school' },
      //     },
      //     {
      //       label: this.$t('employerMsgs.employers'),
      //       route: { name: 'manage-employers' },
      //       icon: { type: 'md-icon', id: 'business' },
      //     },
      //     {
      //       label: 'Organizations',
      //       route: { name: 'manage-organizations' },
      //       icon: { type: 'md-icon', id: 'business_center' },
      //     },
      //     {
      //       label: 'Partners',
      //       route: { name: 'manage-partners' },
      //       icon: { type: 'md-icon', id: 'group' },
      //     },
      //     {
      //       label: 'Initiative Sites',
      //       icon: { type: 'md-icon', id: 'settings' },
      //       id: 'website-config',
      //       children: [
      //         {
      //           label: 'Basic',
      //           route: { name: 'basic-config' },
      //           icon: { type: 'md-icon', id: 'settings' },
      //         },
      //         {
      //           label: 'Home',
      //           route: { name: 'homepage-config' },
      //           icon: { type: 'md-icon', id: 'settings' },
      //         },
      //         {
      //           label: 'Pages',
      //           route: { name: 'pages-config' },
      //           icon: { type: 'md-icon', id: 'settings' },
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Career Preferences',
      //       route: { name: 'sa-manage-career-preferences' },
      //       icon: { type: 'md-icon', id: 'local_hospital' },
      //     },
      //     {
      //       label: 'Programs',
      //       route: { name: 'sa-manage-programs' },
      //       icon: { type: 'md-icon', id: 'local_library' },
      //     },
      //     {
      //       label: 'Jobs',
      //       route: { name: 'jobs-management-list' },
      //       icon: { type: 'md-icon', id: 'work' },
      //     },

      //     {
      //       label: 'Events',
      //       route: { name: 'sa-manage-events' },
      //       icon: { type: 'md-icon', id: 'event' },
      //     },
      //     {
      //       label: 'Blogs',
      //       route: { name: 'sa-manage-blogs' },
      //       icon: { type: 'md-icon', id: 'vignette' },
      //     },
      //     {
      //       label: 'Resources',
      //       route: { name: 'manage-resources' },
      //       icon: { type: 'md-icon', id: 'folder' },
      //     },
      //     {
      //       label: 'Sponsored Training',
      //       icon: { type: 'md-icon', id: 'location_on' },
      //       id: 'location',
      //       children: [
      //         {
      //           label: 'States',
      //           route: { name: 'sa-manage-states' },
      //           exact: true,
      //         },
      //         {
      //           label: 'Cities',
      //           route: { name: 'sa-manage-cities' },
      //           exact: true,
      //         },
      //         {
      //           label: 'Universities',
      //           route: { name: 'manage-unis' },
      //           icon: { type: 'md-icon', id: 'chrome_reader_mode' },
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Sponsorships',
      //       route: { name: 'scholarships-management-list' },
      //       icon: { type: 'md-icon', id: 'stars' },
      //     },
      //     {
      //       label: 'Prizes',
      //       icon: { type: 'md-icon', id: 'card_giftcard' },
      //       id: 'prize',
      //       children: [
      //         {
      //           label: 'School Prizes',
      //           route: { name: 'manage-prize' },
      //           exact: true,
      //         },
      //         {
      //           label: 'Redemptions',
      //           route: { name: 'manage-redeem-prizes' },
      //           exact: true,
      //         },
      //       ],
      //     },
      //   ],

      //   school: [
      //     {
      //       label: 'Home',
      //       route: { name: 'dashboard-school' },
      //       icon: { type: 'md-icon', id: 'home' },
      //     },
      //     {
      //       label: 'Students',
      //       icon: { type: 'md-icon', id: 'school' },
      //       id: 'students',
      //       children: [
      //         {
      //           label: 'Profiles',
      //           route: { name: 'schl-students-list' },
      //         },
      //         {
      //           label: 'Documents',
      //           route: { name: 'schl-std-secure-docs' },
      //           exact: true,
      //         },
      //         {
      //           label: 'Skills',
      //           route: { name: 'student-skill' },
      //         },
      //         {
      //           label: 'Questions',
      //           route: { name: 'student-questions' },
      //         },
      //         {
      //           label: 'Testimonials',
      //           route: { name: 'manage-student-testimonial-list' },
      //         },
      //       ],
      //     },
      //     // {
      //     //   label: 'Partnerships',
      //     //   icon: { type: 'md-icon', id: 'business' },
      //     //   id: 'partnerships',
      //     //   children: [
      //     //     {
      //     //       label: 'Employers',
      //     //       route: { name: 'manage-partnerships' },
      //     //     },
      //     //     {
      //     //       label: 'Universities',
      //     //       route: { name: 'manage-partnered-universities' },
      //     //       exact: true,
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   label: 'Resources',
      //     //   route: { name: 'school-manage-resources' },
      //     //   icon: { type: 'md-icon', id: 'folder' },
      //     //   id: 'resources',
      //     // },
      //     // {
      //     //   label: 'Employers',
      //     //   icon: { type: 'md-icon', id: 'business' },
      //     //   id: 'employers',
      //     //   children: [
      //     //     {
      //     //       label: 'Profiles',
      //     //       route: { name: 'schl-employers-list' },
      //     //     },
      //     //     {
      //     //       label: 'Partnerships',
      //     //       route: { name: 'manage-partnerships' },
      //     //       exact: true,
      //     //     },
      //     //   ],
      //     // },
      //   ],
      //   partner: [
      //     {
      //       label: 'Jobs',
      //       route: { name: 'partner-jobs-listing' },
      //       icon: { type: 'md-icon', id: 'work' },
      //       id: 'jobs',
      //     },
      //   ],
      //   employer: [
      //     {
      //       label: 'Home',
      //       route: { name: 'dashboard-employer' },
      //       icon: { type: 'md-icon', id: 'home' },
      //     },
      //     {
      //       label: 'Candidates',
      //       icon: { type: 'md-icon', id: 'school' },
      //       id: 'candidates',
      //       children: [
      //         {
      //           label: 'All Candidates',
      //           route: { name: 'employer-candidates-list' },
      //         },
      //         {
      //           label: 'Unlocked Candidates',
      //           route: { name: 'employer-unlocked-candidates-list', params: { to: 'unlock' } },
      //           exact: true,
      //         },
      //         {
      //           label: 'Hires',
      //           route: { name: 'hires' },
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Training Providers',
      //       icon: { type: 'md-icon', id: 'account_balance' },
      //       id: 'training_providers',
      //       children: [
      //         {
      //           label: 'All Providers',
      //           route: { name: 'emp-schools-list' },
      //         },
      //         {
      //           label: 'Partnerships',
      //           route: { name: 'manage-partnerships' },
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Sponsorships',
      //       icon: { type: 'md-icon', id: 'stars' },
      //       id: 'sponsorships',
      //       children: [
      //         {
      //           label: 'Postings',
      //           route: { name: 'scholarships-management-list' },
      //           exact: true,
      //         },
      //         {
      //           label: 'Questions',
      //           route: { name: 'emp-manage-questions' },
      //         },
      //         {
      //           label: 'Submissions',
      //           route: { name: 'std-apps-list' },
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Jobs',
      //       route: { name: 'jobs-management-list' },
      //       icon: { type: 'md-icon', id: 'work' },
      //     },
      //   ],

      //   student: [
      //     {
      //       label: 'Jobs',
      //       icon: { type: 'md-icon', id: 'work' },
      //       children: [
      //         {
      //           label: 'All Jobs',
      //           route: { name: 'student-jobs-listing' },
      //           exact: true,
      //         },
      //         {
      //           label: 'Featured Jobs',
      //           route: { name: 'student-local-jobs-listing' },
      //           exact: true,
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Companies',
      //       route: { name: 'student-employers-list' },
      //       icon: { type: 'md-icon', id: 'business' },
      //     },
      //     {
      //       label: 'Skills',
      //       route: { name: 'student-skill' },
      //       icon: { type: 'md-icon', id: 'videocam' },
      //     },
      //     // TODO: Uncomment after interviews integration.
      //     // {
      //     //   label: 'Interviews',
      //     //   route: { name: 'std-manage-resume' },
      //     //   icon: { type: 'md-icon', id: 'speaker_notes' },
      //     // },

      //     // TODO: Careers and events temporarily disabled for students.
      //     // {
      //     //   label: 'Careers',
      //     //   route: { name: 'student-careers' },
      //     //   icon: { type: 'md-icon', id: 'local_hospital' },
      //     // },
      //     // {
      //     //   label: 'Events',
      //     //   route: { name: 'student-events' },
      //     //   icon: { type: 'md-icon', id: 'event' },
      //     // },
      //   ],
      //   organization: [
      //     {
      //       label: 'Home',
      //       route: { name: 'org-admin-home' },
      //       icon: { type: 'md-icon', id: 'home' },
      //     },
      //     {
      //       label: 'Candidates',
      //       route: { name: 'org-students-list' },
      //       icon: { type: 'md-icon', id: 'school' },
      //     },
      //     {
      //       label: 'Schools',
      //       route: { name: 'org-schools-list' },
      //       icon: { type: 'md-icon', id: 'account_balance' },
      //     },
      //     {
      //       label: 'Jobs',
      //       route: { name: 'jobs-management-list' },
      //       icon: { type: 'md-icon', id: 'work' },
      //     },
      //     {
      //       label: 'Employers',
      //       route: { name: 'manage-org-employers' },
      //       icon: { type: 'md-icon', id: 'business' },
      //     },
      //     {
      //       label: 'Sponsorships',
      //       route: { name: 'scholarships-management-list' },
      //       icon: { type: 'md-icon', id: 'stars' },
      //     },

      //     {
      //       label: 'Initiative Sites',
      //       icon: { type: 'md-icon', id: 'settings' },
      //       id: 'website-config',
      //       children: [
      //         {
      //           label: 'Basic',
      //           route: { name: 'basic-config' },
      //           icon: { type: 'md-icon', id: 'settings' },
      //         },
      //         {
      //           label: 'Home',
      //           route: { name: 'homepage-config' },
      //           icon: { type: 'md-icon', id: 'settings' },
      //         },
      //         {
      //           label: 'Pages',
      //           route: { name: 'pages-config' },
      //           icon: { type: 'md-icon', id: 'settings' },
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Application Questions',
      //       route: { name: 'manage-questions' },
      //       icon: { type: 'md-icon', id: 'help' },
      //     },
      //   ],
      //   unauthRoutes: [
      //     {
      //       label: 'Students',
      //       route: { name: 'sch-public-home' },
      //       icon: { type: 'md-icon', id: 'school' },
      //       exact: true,
      //     },
      //     {
      //       label: 'Employers',
      //       route: { name: 'sch-public-parnerships' },
      //       icon: { type: 'md-icon', id: 'business' },
      //       exact: true,
      //     },
      //     // {
      //     //   label: 'Interviews',
      //     //   route: { name: 'sch-public-interviews' },
      //     //   icon: { type: 'md-icon', id: 'speaker_notes' },
      //     //   exact: true,
      //     // },
      //     // {
      //     //   label: 'Careers',
      //     //   route: { name: 'sch-public-careers' },
      //     //   icon: { type: 'md-icon', id: 'local_hospital' },
      //     //   exact: true,
      //     // },
      //     // {
      //     //   label: 'Testimonials',
      //     //   route: { name: 'sch-public-testimonials' },
      //     //   icon: { type: 'md-icon', id: 'rate_review' },
      //     //   exact: true,
      //     // },
      //   ],
      // },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool', 'getPaymentStatus']),
    ...mapGetters('partnership', ['getPartnershipStatus']),
    menu() {
      return {
        default: [
          {
            label: this.$t('home'),
            route: { name: 'home' },
            icon: { type: 'md-icon', id: 'home' },
          },
        ],
        superAdmin: [
          {
            label: this.$t('home'),
            route: { name: 'admin-dashboard' },
            icon: { type: 'md-icon', id: 'home' },
          },
          {
            label: 'Users',
            route: { name: 'manage-users' },
            icon: { type: 'md-icon', id: 'person' },
          },
          {
            label: 'Partnerships',
            route: { name: 'manage-all-partnerships' },
            icon: { type: 'md-icon', id: 'business' },
          },
          {
            label: 'Feature Updates',
            route: { name: 'manage-features' },
            icon: { type: 'md-icon', id: 'lightbulb' },
          },

          // {
          //   label: this.$t('schoolMsgs.schools'),
          //   route: { name: 'manage-schools' },
          //   icon: { type: 'md-icon', id: 'account_balance' },
          // },
          {
            label: this.$t('schoolMsgs.schools'),
            icon: { type: 'md-icon', id: 'account_balance' },
            id: 'schools-admin-account',
            children: [
              {
                label: 'School Accounts',
                route: { name: 'manage-schools' },
                icon: { type: 'md-icon', id: 'account_balance' },
              },
              {
                label: 'Interested Schools',
                route: { name: 'manage-interested-schools' },
                icon: { type: 'md-icon', id: 'account_balance' },
              },
            ],
          },
          {
            label: this.$t('studentMsgs.students'),
            route: { name: 'manage-students' },
            icon: { type: 'md-icon', id: 'school' },
          },
          {
            label: this.$t('employerMsgs.employers'),
            route: { name: 'manage-employers' },
            icon: { type: 'md-icon', id: 'business' },
          },
          {
            label: 'Organizations',
            route: { name: 'manage-organizations' },
            icon: { type: 'md-icon', id: 'business_center' },
          },
          {
            label: 'Advisors',
            route: { name: 'sa-manage-advisors' },
            icon: { type: 'md-icon', id: 'assignment_ind' },
          },
          {
            label: 'CampusGPTs',
            route: { name: 'manage-gpts' },
            icon: { type: 'md-icon', id: 'apps' },
          },
          {
            label: 'Partners',
            route: { name: 'manage-partners' },
            icon: { type: 'md-icon', id: 'group' },
          },
          {
            label: 'Initiative Sites',
            icon: { type: 'md-icon', id: 'settings' },
            id: 'website-config',
            children: [
              {
                label: 'Basic',
                route: { name: 'basic-config' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Home',
                route: { name: 'homepage-config' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Pages',
                route: { name: 'pages-config' },
                icon: { type: 'md-icon', id: 'settings' },
              },
            ],
          },

          {
            label: 'Programs',
            route: { name: 'sa-manage-programs' },
            icon: { type: 'md-icon', id: 'local_library' },
          },
          {
            label: 'Jobs',
            route: { name: 'jobs-management-list' },
            icon: { type: 'md-icon', id: 'work' },
          },

          {
            label: 'Events',
            route: { name: 'sa-manage-events' },
            icon: { type: 'md-icon', id: 'event' },
          },
          {
            label: 'Blogs',
            route: { name: 'sa-manage-blogs' },
            icon: { type: 'md-icon', id: 'vignette' },
          },

          {
            label: 'Resources',
            route: { name: 'manage-resources' },
            icon: { type: 'md-icon', id: 'folder' },
          },
          {
            label: 'CareCampus',
            icon: { type: 'md-icon', id: 'location_on' },
            id: 'location',
            children: [
              {
                label: 'States',
                route: { name: 'sa-manage-states' },
                exact: true,
              },
              {
                label: 'Districts',
                route: { name: 'sa-manage-cities' },
                exact: true,
              },
              {
                label: 'Universities',
                route: { name: 'manage-unis' },
                icon: { type: 'md-icon', id: 'chrome_reader_mode' },
              },
              {
                label: 'Connect requests',
                route: { name: 'sa-manage-connections' },
              },
            ],
          },
          {
            label: 'Careers',
            icon: { type: 'md-icon', id: 'assessment' },
            id: 'careers',
            children: [
              {
                label: 'Resumes',
                route: { name: 'user-resumes-list' },
              },
              {
                label: 'Career Pathways',
                route: { name: 'sa-manage-career-pathways' },
                icon: { type: 'md-icon', id: 'local_hospital' },
              },
              {
                label: 'Assessments',
                route: { name: 'manage-assessments' },
                icon: { type: 'md-icon', id: 'assessment' },
              },
            ],
          },
          // {
          //   label: 'Sponsorships',
          //   route: { name: 'scholarships-management-list' },
          //   icon: { type: 'md-icon', id: 'stars' },
          // },
          {
            label: 'Sponsorships',
            icon: { type: 'md-icon', id: 'stars' },
            id: 'sponsorships',
            children: [
              {
                label: 'Postings',
                route: { name: 'scholarships-management-list' },
                exact: true,
              },

              {
                label: 'Spotlights',
                route: { name: 'spotlight-list' },
              },

              {
                label: 'External',

                route: { name: 'external-scholarships-list' },
              },
            ],
          },
          {
            label: 'Simulations',
            icon: { type: 'md-icon', id: 'mic' },
            id: 'simulations',
            children: [
              {
                label: 'Listings',
                route: { name: 'simulations-management-list' },
                exact: true,
              },
              {
                label: 'Submissions',
                route: { name: 'simulations-submission-list' },
                exact: true,
              },
            ],
          },
          {
            label: 'AI Assistant',
            icon: { type: 'md-icon', id: 'bolt' },
            id: 'calls-logs',
            children: [
              {
                label: 'Call Logs',
                route: { name: 'calls-logs' },
                exact: true,
              },
            ],
          },
          {
            label: 'Prizes',
            icon: { type: 'md-icon', id: 'card_giftcard' },
            id: 'prize',
            children: [
              {
                label: 'School Prizes',
                route: { name: 'manage-prize' },
                exact: true,
              },
              {
                label: 'Redemptions',
                route: { name: 'manage-redeem-prizes' },
                exact: true,
              },
            ],
          },
        ],

        school: [
          {
            label: 'Home',
            route: { name: 'dashboard-school' },
            icon: { type: 'md-icon', id: 'home' },
          },
          {
            label: 'Students',
            icon: { type: 'md-icon', id: 'school' },
            id: 'students',
            children: [
              {
                label: 'Profiles',
                route: { name: 'schl-students-list' },
              },
              {
                label: 'Documents',
                route: { name: 'schl-std-secure-docs' },
                exact: true,
              },

              ...([SCH_PAYMENT_PLANS.PAID_PLAN].includes(
                get(this.getLoggedInUser, 'linked_entity.sch_payment_plan.title'),
              )
                ? [
                    {
                      label: 'Skills',
                      route: { name: 'student-skill' },
                      exact: true,
                    },
                    {
                      label: 'Questions',
                      route: { name: 'student-questions' },
                    },
                  ]
                : []),
              {
                label: 'Testimonials',
                route: { name: 'manage-student-testimonial-list' },
              },
              {
                label: 'Spotlights',
                route: { name: 'spotlight-list' },
              },
            ],
          },
          ...([SCH_PAYMENT_PLANS.BASIC_PLAN, SCH_PAYMENT_PLANS.PAID_PLAN].includes(
            get(this.getLoggedInUser, 'linked_entity.sch_payment_plan.title'),
          )
            ? [
                {
                  label: 'Attendance',
                  icon: { type: 'md-icon', id: 'calendar_today' },
                  id: 'attendance',
                  children: [
                    {
                      label: 'Mark',
                      route: { name: 'mark-attendance' },
                      exact: true,
                    },
                    {
                      label: 'View',
                      route: { name: 'view-attendance' },
                      exact: true,
                    },
                  ],
                },
              ]
            : []),
          {
            label: 'Tags',
            route: { name: 'manage-tags' },
            icon: { type: 'md-icon', id: 'local_offer' },
          },
          {
            label: 'AI Assistant',
            icon: { type: 'md-icon', id: 'bolt' },
            id: 'calls-logs',
            children: [
              {
                label: 'Call Logs',
                route: { name: 'calls-logs' },
                exact: true,
              },
            ],
          },
          // {
          //   label: 'Spotlights',
          //   route: { name: 'spotlight-list' },
          //   icon: { type: 'md-icon', id: 'spa' },
          // },
          // {
          //   label: 'Partnerships',
          //   icon: { type: 'md-icon', id: 'business' },
          //   id: 'partnerships',
          //   children: [
          //     {
          //       label: 'Employers',
          //       route: { name: 'manage-partnerships' },
          //     },
          //     {
          //       label: 'Universities',
          //       route: { name: 'manage-partnered-universities' },
          //       exact: true,
          //     },
          //   ],
          // },
          // {
          //   label: 'Resources',
          //   route: { name: 'school-manage-resources' },
          //   icon: { type: 'md-icon', id: 'folder' },
          //   id: 'resources',
          // },
          // {
          //   label: 'Employers',
          //   icon: { type: 'md-icon', id: 'business' },
          //   id: 'employers',
          //   children: [
          //     {
          //       label: 'Profiles',
          //       route: { name: 'schl-employers-list' },
          //     },
          //     {
          //       label: 'Partnerships',
          //       route: { name: 'manage-partnerships' },
          //       exact: true,
          //     },
          //   ],
          // },
        ],
        partner: [
          {
            label: 'Jobs',
            route: { name: 'partner-jobs-listing' },
            icon: { type: 'md-icon', id: 'work' },
            id: 'jobs',
          },
        ],
        employer_home: [
          {
            label: 'Dashboard',
            route: { name: 'dashboard-employer' },
            icon: { type: 'md-icon', id: 'home' },
          },
        ],
        employer_crm: [
          {
            label: 'Candidates',
            icon: { type: 'md-icon', id: 'school' },
            id: 'candidates',
            children: [
              {
                label: 'Associated Candidates',
                route: { name: 'associated-students-list', params: { to: 'associated' } },
                exact: true,
              },
              {
                label: 'All Candidates',
                route: { name: 'employer-candidates-list' },
              },
              {
                label: 'Unlocked Candidates',
                route: { name: 'employer-unlocked-candidates-list', params: { to: 'unlock' } },
                exact: true,
              },
              
              {
                label: 'Hires',
                route: { name: 'hires' },
              },
            ],
          },
          {
            label: 'Education Partners',
            icon: { type: 'md-icon', id: 'account_balance' },
            id: 'training_providers',
            children: [
              {
                label: 'All Schools',
                route: { name: 'emp-schools-list' },
              },
              {
                label: 'Partnerships',
                route: { name: 'manage-partnerships' },
              },
            ],
          },
        ],
        employer_tools: [
          {
            label: 'Skill Simulations',
            icon: { type: 'md-icon', id: 'mic' },
            id: 'simulations',
            children: [
              {
                label: 'Listings',
                route: { name: 'simulations-management-list' },
                exact: true,
              },
              {
                label: 'Submissions',
                route: { name: 'simulations-submission-list' },
                exact: true,
              },
            ],
          },
          {
            label: 'Jobs',
            route: { name: 'jobs-management-list' },
            icon: { type: 'md-icon', id: 'work' },
          },
          {
            label: 'Sponsorships',
            icon: { type: 'md-icon', id: 'stars' },
            id: 'sponsorships',
            children: [
              {
                label: 'Postings',
                id: '1',
                route: { name: 'scholarships-management-list' },
                exact: true,
              },
              {
                label: 'Questions',
                id: '2',
                route: { name: 'emp-manage-questions' },
              },
              {
                label: 'Submissions',
                id: '3',
                route: { name: 'std-apps-list' },
              },
              {
                label: 'Spotlights',
                id: '4',
                route: { name: 'spotlight-list' },
              },
            ],
          },
        ],
        employer_cs_analytics: [
          ...(get(this.getLoggedInUser, 'linked_entity.organization')
            ? [
                {
                  label: 'Analytics',
                  icon: { type: 'md-icon', id: 'assessment' },
                  id: 'careers',
                  children: [
                    {
                      label: 'Resumes',
                      route: { name: 'user-resumes-list' },
                    },
                    {
                      label: 'Assessments',
                      route: { name: 'manage-assessments' },
                      icon: { type: 'md-icon', id: 'assessment' },
                    },
                  ],
                },
              ]
            : []),
        ],

        student: [
          {
            label: 'Jobs',
            icon: { type: 'md-icon', id: 'work' },
            children: [
              {
                label: 'All Jobs',
                route: { name: 'student-jobs-listing' },
                exact: true,
              },
              {
                label: 'Featured Jobs',
                route: { name: 'student-local-jobs-listing' },
                exact: true,
              },
            ],
          },
          {
            label: 'Companies',
            route: { name: 'student-employers-list' },
            icon: { type: 'md-icon', id: 'business' },
          },
          {
            label: 'Skills',
            route: { name: 'student-skill' },
            icon: { type: 'md-icon', id: 'videocam' },
          },
          // TODO: Uncomment after interviews integration.
          // {
          //   label: 'Interviews',
          //   route: { name: 'std-manage-resume' },
          //   icon: { type: 'md-icon', id: 'speaker_notes' },
          // },

          // TODO: Careers and events temporarily disabled for students.
          // {
          //   label: 'Careers',
          //   route: { name: 'student-careers' },
          //   icon: { type: 'md-icon', id: 'local_hospital' },
          // },
          // {
          //   label: 'Events',
          //   route: { name: 'student-events' },
          //   icon: { type: 'md-icon', id: 'event' },
          // },
        ],
        org_home: [
          {
            label: 'Dashboard',
            route: { name: 'org-admin-home' },
            icon: { type: 'md-icon', id: 'home' },
          },
        ],
        org_crm: [
          {
            label: 'Participants',
            icon: { type: 'md-icon', id: 'school' },
            id: 'candidates',
            children: [
              {
                label: 'Associated Candidates',
                route: { name: 'associated-students-list', params: { to: 'associated' } },
                exact: true,
              },
              {
                label: 'All Candidates',
                route: { name: 'org-students-list' },
              },
              {
                label: 'Unlocked Candidates',

                route: { name: 'org-unlock-students-list', params: { to: 'unlock' } },
                exact: true,
              },
              
            ],
          },
          // {
          //   label: 'Candidates',
          //   route: { name: 'org-students-list' },
          //   icon: { type: 'md-icon', id: 'school' },
          // },
          {
            label: 'Education Partners',
            route: { name: 'org-schools-list' },
            icon: { type: 'md-icon', id: 'account_balance' },
          },
          {
            label: 'Employer Partners',
            route: { name: 'manage-org-employers' },
            icon: { type: 'md-icon', id: 'business' },
          },
          {
            label: 'Advisors',
            route: { name: 'manage-org-advisors' },
            icon: { type: 'md-icon', id: 'assignment_ind' },
          },
        ],
        org_tools: [
          {
            label: 'Skill Simulations',
            icon: { type: 'md-icon', id: 'mic' },
            id: 'simulations',
            children: [
              {
                label: 'Listings',
                route: { name: 'simulations-management-list' },
                exact: true,
              },
              {
                label: 'Submissions',
                route: { name: 'simulations-submission-list' },
                exact: true,
              },
            ],
          },
          {
            label: 'Jobs',
            route: { name: 'jobs-management-list' },
            icon: { type: 'md-icon', id: 'work' },
          },
          {
            label: 'Sponsorships',
            icon: { type: 'md-icon', id: 'stars' },
            id: 'sponsorships',
            children: [
              {
                label: 'Postings',
                route: { name: 'scholarships-management-list' },
                exact: true,
              },
              {
                label: 'Questions',
                route: { name: 'manage-questions' },
              },
              {
                label: 'Spotlights',
                route: { name: 'spotlight-list' },
              },
            ],
          },
          {
            label: 'Events',
            route: { name: 'manage-org-events' },
            icon: { type: 'md-icon', id: 'event' },
          },
        ],
        org_cs: [
          {
            label: 'Build Site',
            icon: { type: 'md-icon', id: 'settings' },
            id: 'website-config',
            children: [
              {
                label: 'Settings',
                route: { name: 'basic-config' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Homepage',
                route: { name: 'homepage-config' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Pages',
                route: { name: 'pages-config' },
                icon: { type: 'md-icon', id: 'settings' },
              },
            ],
          },
          {
            label: 'Analytics',
            icon: { type: 'md-icon', id: 'assessment' },
            id: 'careers',
            children: [
              {
                label: 'Resumes',
                route: { name: 'user-resumes-list' },
              },
              {
                label: 'Assessments',
                route: { name: 'manage-assessments' },
                icon: { type: 'md-icon', id: 'assessment' },
              },
            ],
          },
          {
            label: 'Inquiries',
            route: { name: 'org-inquiries-list' },
            icon: { type: 'md-icon', id: 'comment' },
          },
        ],

        unauthRoutes: [
          {
            label: 'For Students',
            route: { name: 'sch-public-home' },
            icon: { type: 'md-icon', id: 'school' },
            exact: true,
          },
          {
            label: 'For Employers',
            route: { name: 'sch-public-parnerships' },
            icon: { type: 'md-icon', id: 'business' },
            exact: true,
          },
          // {
          //   label: 'Interviews',
          //   route: { name: 'sch-public-interviews' },
          //   icon: { type: 'md-icon', id: 'speaker_notes' },
          //   exact: true,
          // },
          // {
          //   label: 'Careers',
          //   route: { name: 'sch-public-careers' },
          //   icon: { type: 'md-icon', id: 'local_hospital' },
          //   exact: true,
          // },
          // {
          //   label: 'Testimonials',
          //   route: { name: 'sch-public-testimonials' },
          //   icon: { type: 'md-icon', id: 'rate_review' },
          //   exact: true,
          // },
        ],
      };
    },
  },
  watch: {
    scrollIntoViewElement: 'scrollIntoView',
    getPaymentStatus: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && this.$refs['schoolSideMenu']) {
          this.schoolKey = 'schoolId';
          this.$refs['schoolSideMenu'].$forceUpdate();
        }
      },
    },
    // getCurrentSchool: {
    //   immediate: true,
    //   deep: true,
    //   handler() {
    //     if (this.$refs['schoolSideMenu']) {
    //       this.$refs['schoolSideMenu'].$forceUpdate();
    //     }
    //   },
    // },
  },
  created() {
    this.$root.$on('fmv::sidebar-menu::state', ({ targetId, opened }) => {
      this.updateSidebarScroll();
      if (opened) {
        this.scrollIntoViewElement = targetId;
      }
    });
    if (get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL) {
      this.fetchPendingPartnerships();
      this.fetchSchool();
    }
  },
  methods: {
    ...mapActions('school', ['getSchool']),
    ...mapActions('partnership', ['updatePendingPartnershipStatus']),
    get,

    async fetchPendingPartnerships() {
      this.arePartnershipPending = true;

      this.updatePendingPartnershipStatus();
      this.arePartnershipPending = false;
    },
    emitState(targetId, opened, open) {
      const state = {
        targetId,
        open,
        opened,
      };
      this.$root.$emit('fmv::sidebar-menu::state', state);
    },
    linkComponent(route) {
      this.$router.push(route);
    },
    openStdModal() {
      this.showStdModal = true;
    },
    hideStdModal() {
      this.showStdModal = false;
    },
    async fetchSchool() {
      try {
        const resp = (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;

        this.subdomain = resp.subdomain.subdomain_url;
      } catch (err) {
        //  USE ERROR
      }

      this.isLoading = false;
    },
    updateSidebarScroll() {
      this.$nextTick(() => {
        if (this.$refs.sidebar) {
          this.$refs.sidebar.update();
        }
      });
    },
    scrollIntoView() {
      this.$nextTick(() => {
        const target = document.querySelector(`#${this.scrollIntoViewElement}`);
        if (target) {
          elementScrollIntoView(target, {
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }
      });
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },
  },
};
</script>
