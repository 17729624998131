import axios from 'axios';

const state = {
  employerFilters: {
    zipcode: null,
    zipcodeRange: 30,

    companyType: [],
    companySize: []
  },

  empSignupStatus: false
};

const getters = {
  getEmployerFilters: state => state.employerFilters,
  getEmpSignupStatus: state => state.empSignupStatus
};

const mutations = {
  updateEmployerFilters: (state, updatedFilters) => {
    state.employerFilters = { ...state.employerFilters, ...updatedFilters };
  },
  updateEmpSignupStatus: (state, res) => {
    state.empSignupStatus = res;
  }
};

const actions = {
  async getAllEmployers(_obj, params) {
    const res = await axios.get(`api/employers/`, { params });
    return res.data;
  },

  async getEmployer(_obj, id) {
    const res = await axios.get(`api/employers/${id}/`);
    return res.data;
  },

  async createEmployer(_obj, data) {
    return axios.post(`api/employers/`, data);
  },

  async updateEmployer(_obj, { id, data }) {
    return axios.patch(`api/employers/${id}/`, data);
  },

  async deleteEmployer(_obj, id) {
    return axios.delete(`api/employers/${id}/`);
  },

  async getEmployerUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/employers/upload-url/`, data)).data.data;
  },

  async updateMyEmployerProfile({ commit }, { data }) {
    const resp = await axios.patch(`api/employers/update-profile/`, data);
    commit('navbarConfig/updateNavbarBrandDetails', { logo: data.logo_url, brandText: data.name }, { root: true });
    return resp;
  },

  async updateEmployerFilters({ commit }, { filters }) {
    commit('updateEmployerFilters', filters);
  },
  async getPublicEmployers(_obj, params) {
    const res = await axios.get(`api/partnerships/public-partnered-employers/`, { params });
    return res.data;
  },
  async getPartneredEmployers(_obj, params) {
    const res = await axios.get(`api/employers/get-partnered-employer/`, { params });
    return res.data;
  },
  async getPublicEmployer(_obj, params) {
    const res = await axios.get(`api/employers/employer-public-details/`, { params });
    return res.data;
  },

  async signupEmployer({ commit }, data) {
    const res = await axios.post(`api/employers/emp-public-signup/`, data);
    commit('updateEmpSignupStatus', true);
    return res;
  },

  updateEmpSignupStatus({ commit }, res) {
    commit('updateEmpSignupStatus', res);
  },

  async verifyEmployerEmail(_obj, data) {
    return axios.post(`api/employers/verify-email/`, data);
  },

  async getDashboard(_obj, params) {
    const res = await axios.get(`api/employers/dashboard/`, { params });
    return res.data;
  },

  async getEmpSetupGuideStatuses(_obj, params) {
    const res = await axios.get(`api/employers/setup-guide-statuses/`, { params });
    return res.data;
  },

  async getOrgShowPartneredSchool(_obj, params) {
    const res = await axios.get(`api/employers/org-schools-allowed/`, { params });
    return res.data;
  }
};

export const employer = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
