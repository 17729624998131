export default {
  methods: {
    makeStdPointToast(points = 1) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'material-icons mr-2' }, 'stars'), `You have earned ${points} point(s)`]),
        autoHideDelay: 5000,
      });
    },
  }
}