import axios from 'axios';
import { get } from 'lodash';
const state = {
  partnershipStatus: null
};

const getters = {
  getPartnershipStatus: state => state.partnershipStatus
};

const mutations = {
  setPartnershipStatus: (state, partnershipStatus) => {
    state.partnershipStatus = partnershipStatus;
  }
};

const actions = {
  async getAllPartnerships(_obj, params) {
    const res = await axios.get(`api/partnerships/`, { params });
    return res.data;
  },

  async getPartnership(_obj, id) {
    const res = await axios.get(`api/partnerships/${id}/`);
    return res.data;
  },
  async getStatus(_obj, params) {
    const res = await axios.get(`api/partnerships/get-status/`, { params });
    return res.data;
  },
  async createPartnership(_obj, data) {
    return axios.post(`api/partnerships/`, data);
  },

  async updatePartnership(_obj, { id, data }) {
    return axios.patch(`api/partnerships/${id}/`, data);
  },

  async deletePartnership(_obj, id) {
    return axios.delete(`api/partnerships/${id}/`);
  },
  async unrevokePartnership(_obj, id) {
    return axios.patch(`api/partnerships/${id}/partnership-unrevoke/`);
  },
  async respondToPartnershipRequest(_obj, { id, data }) {
    return axios.patch(`api/partnerships/${id}/respond-request/`, data);
  },
  async getAllPartneredSchools(_obj, params) {
    const res = await axios.get(`api/partnerships/get-partnered-schools/`, { params });
    return res.data;
  },

  async getPartnersStatuses(_obj, data) {
    const res = await axios.post(`api/partnerships/get-partners-statuses/`, data);
    return res.data;
  },
  async getCalcPartnershipFee(_obj, params) {
    const res = await axios.get(`api/partnerships/calc-partnership-fees/`, { params });
    return res.data;
  },
  async requestWithPaymentDetails(_obj, data) {
    return axios.post(`api/partnerships/request-with-payment-details/`, data);
  },
  async requestPartnershipPaymentSuccess(_obj, params) {
    const res = await axios.get(`api/partnerships/request-payment-success/`, { params });
    return res.data;
  },
  async requestPartnershipPaymentCancel(_obj, params) {
    const res = await axios.get(`api/partnerships/request-payment-cancel/`, { params });
    return res.data;
  },
  async acceptPartnershipPaymentSuccess(_obj, params) {
    const res = await axios.get(`api/partnerships/accept-partnership-payment-det/`, { params });
    return res.data;
  },
  async cancelRequestPartnership(_obj, id) {
    return axios.delete(`api/partnerships/${id}/cancel-partnership/`);
  },
  async getPendingPartnershipStatus(_obj, params) {
    const res = await axios.get(`api/partnerships/pending-partnerships/`, { params });
    return res.data;
  },
  async updatePendingPartnershipStatus({ dispatch, commit }) {
    const resp = await dispatch('getPendingPartnershipStatus');

    commit('setPartnershipStatus', get(resp, 'data.pending_partnerships'));
  }
};

export const partnership = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
