import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import {
  auth,
  employer,
  fileDownload,
  layoutConfig,
  navbarConfig,
  s3Upload,
  school,
  settings,
  sidebarConfig,
  sidebarMenuConfig,
  student,
  careerPreference,
  job,
  event,
  partnership,
  notifications,
  invite,
  testimonial,
  signUpRequest,
  program,
  payments,
  hire,
  credit,
  skills,
  comments,
  feedback,
  questions,
  likes,
  user,
  location,
  scholarships,
  blog,
  partner,
  organization,
  applicationForm,
  sections,
  uni,
  featureUpdates,
  resume,
  assessment,
  stories,
  gpts,
  emailSubscription,
  simulations,
  attendance,
  inquiries,
  advisors
} from './modules';

Vue.use(Vuex);

export const state = () => ({
  brand: 'Transition'
});

export const createStore = () => {
  return new Vuex.Store({
    state,
    getters: { settings: state => state.settings.state },
    mutations: {},
    actions: {},
    modules: {
      settings,
      layoutConfig,
      sidebarConfig,
      sidebarMenuConfig,
      navbarConfig,
      auth,
      fileDownload,
      s3Upload,
      school,
      student,
      employer,
      careerPreference,
      job,
      event,
      partnership,
      notifications,
      invite,
      testimonial,
      signUpRequest,
      program,
      payments,
      hire,
      credit,
      skills,
      comments,
      feedback,
      questions,
      likes,
      user,
      location,
      scholarships,
      blog,
      partner,
      organization,
      applicationForm,
      sections,
      uni,
      featureUpdates,
      resume,
      assessment,
      stories,
      gpts,
      emailSubscription,
      simulations,
      attendance,
      inquiries,
      advisors
    },
    plugins: [createPersistedState({ paths: ['auth', 'student'] })]
  });
};

const store = createStore();
export default store;
