import axios from 'axios';

const state = {};
const getters = {};

const mutations = {};

const actions = {
  async getAllPartners(_obj, params) {
    const res = await axios.get(`api/partners/`, { params });
    return res.data;
  },

  async getActivePartners(_obj, params) {
    const res = await axios.get(`api/partners/active-partners/`, { params });
    return res.data;
  },

  async getPartner(_obj, id) {
    const res = await axios.get(`api/partners/${id}/`);
    return res.data;
  },

  async createPartner(_obj, data) {
    return axios.post(`api/partners/`, data);
  },

  async updatePartner(_obj, { id, data }) {
    return axios.patch(`api/partners/${id}/`, data);
  }
};

export const partner = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
