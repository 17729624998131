import Vue from 'vue';
import VueCurrencyInput from 'vue-currency-input';

const pluginOptions = {
  globalOptions: {
    locale: 'en',
    currency: 'USD'
  }
};
Vue.use(VueCurrencyInput, pluginOptions);
