import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllEvents(_obj, params) {
    const res = await axios.get(`api/events/`, { params });
    return res.data;
  },

  async getEvent(_obj, id) {
    const res = await axios.get(`api/events/${id}/`);
    return res.data;
  },

  async createEvent(_obj, data) {
    return axios.post(`api/events/`, data);
  },

  async updateEvent(_obj, { id, data }) {
    return axios.patch(`api/events/${id}/`, data);
  },

  async deleteEvent(_obj, id) {
    return axios.delete(`api/events/${id}/`);
  },

  async getEventImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/events/image-upload-url/`, data)).data.data;
  },

  async getEventStudentCounts(_obj, queryData) {
    return (await axios.post(`api/events/get-students-count/`, queryData)).data.data;
  },

  async registerEvent(_obj, data) {
    return (await axios.post(`api/events/register/`, data)).data;
  },

  async unregisterEvent(_obj, data) {
    return (await axios.post(`api/events/unregister/`, data)).data;
  },

  async checkMyEventRegistration(_obj, params) {
    return (await axios.get(`api/events/my-reg-status/`, { params })).data.data;
  },

  async getEventsAttendeeCounts(_obj, data) {
    return (await axios.post(`api/events/attendee-counts/`, data)).data.data;
  },

  async getEventAttendees(_obj, params) {
    return (await axios.get(`api/events/attendees/`, { params })).data.data;
  }
};

export const event = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
