<template>
  <b-modal ref="stdCenterModal" hide-footer title="View Your Career Center" centered lazy @hide="hideModal" size="lg">
    <h2 class="text-center mb-0" style="font-weight: bold">View Career Center</h2>

    <page-separator title="" />

    <div class="row">
      <div class="col-lg-12">
        <div class="d-flex justify-content-center" style="max-width: 100%">
          <div class="mb-0">
            <p style="font-size: 16px">Choose your preferred way to explore your career center.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-light border border-6 border-secondary rounded p-4" role="alert" style="">
      <div class="row">
        <div class="col-lg-12 d-flex">
          <div class="flex" style="max-width: 100%">
            <div class="">
              <h3 class="text-dark" style="font-size: 19px">Public View</h3>
              <p class="text-90 mb-3" style="font-size: 15px">
                Accessible to all, this link provides an overview of the career center for current and potential
                students or employers.
              </p>

              <p class="text-90 mb-3">
                <b>Note:</b> If you've already logged in, you'll be redirected to your private portal. You'll have to
                log out first to view the public site.
              </p>

              <div class="d-flex justify-content-center">
                <a :href="subdomain" target="_blank" class="btn btn-primary"
                  >Explore Public Site <i class="fas fa-external-link-alt ml-2"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3" v-if="demoEmail && demoPassword">
        <div class="col-lg-12 d-flex">
          <div class="flex" style="max-width: 100%">
            <div class="">
              <h3 class="text-dark" style="font-size: 19px">Private View</h3>
              <p class="text-90 mb-3" style="font-size: 15px">
                Exclusively for invited students, this portal unlocks additional resources, document uploads, and more.
                Use the demo credentials to log in.
              </p>

              <p class="text-90 mb-3">
                <b>Note:</b> Feel free to reset the password after logging in. (In case you've already reset the
                password, use your chosen one instead of the one given below.)
              </p>

              <p>
                Email:
                <b
                  v-b-popover.hover.top
                  title="Click to copy"
                  class="clickable-item"
                  @click.prevent="copyText(demoEmail)"
                  >{{ demoEmail }}</b
                ><br />
                Password:
                <b
                  v-b-popover.hover.top
                  title="Click to copy"
                  class="clickable-item"
                  @click.prevent="copyText(demoPassword)"
                  >{{ showPass ? demoPassword : '*******' }}</b
                >
                <i
                  class="fas clickable-item ml-2"
                  @click.prevent="() => (showPass = !showPass)"
                  :class="showPass ? 'fa-eye-slash' : 'fa-eye'"
                ></i>
              </p>

              <div class="d-flex justify-content-center">
                <a :href="`${subdomain}/login`" target="_blank" class="btn btn-primary mx-auto"
                  >Access Private Portal <i class="fas fa-external-link-alt ml-2"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLE_TYPES } from '@/common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'SchoolsModal',
  components: { PageSeparator },

  props: {
    showModal: { type: Boolean, default: false },
    scholarship: { type: Object, default: () => {} },
    schools: { type: Array, default: () => [] },
  },

  data() {
    return {
      isLoading: false,
      subdomain: '',
      demoEmail: '',
      demoPassword: '',

      showPass: false,
    };
  },

  validations() {
    return {};
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  methods: {
    ...mapActions('school', ['getSchool', 'getSchoolDemoStdAccount']),
    get,

    async fetchSchool() {
      try {
        const resp = (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;

        this.subdomain = resp.subdomain.subdomain_url;
      } catch (err) {
        //  USE ERROR
      }

      this.isLoading = false;
    },

    async fetchDemoStdAccount() {
      try {
        const resp = (await this.getSchoolDemoStdAccount()).data;
        this.demoEmail = resp.demo_email;
        this.demoPassword = resp.demo_password;
      } catch (err) {
        //  USE ERROR
      }

      this.isLoading = false;
    },

    async hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.stdCenterModal.hide();
      this.hideModal();
    },

    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.makeToast({ variant: 'success', msg: 'Copied to clipboard.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.stdCenterModal.show();
      }
    },
  },
  async mounted() {
    if (get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL) {
      this.fetchDemoStdAccount();
      this.fetchSchool();
    }
  },
};
</script>
