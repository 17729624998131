import store from '../store';

export async function validateInviteToken(to, from, next) {
  if (store.getters['auth/isLoggedIn']) {
    await store.dispatch('auth/logout');
  }
  try {
    await store.dispatch('invite/validateInviteToken', {
      token: to.query.token || ''
    });
    next();
  } catch (error) {
    store.dispatch('invite/updateInviteTokenInvalid', true);
    next({ name: 'login' });
  }
}
