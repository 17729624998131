import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllAssessments(_obj, params) {
    const res = await axios.get(`api/assessments/student-assessments/`, { params });
    return res.data;
  },

  async getAssessment(_obj, id) {
    const res = await axios.get(`api/assessments/student-assessments/${id}/`);
    return res.data;
  },
  async getCareersByIds(_obj, data) {
    return (await axios.post(`api/assessments/student-assessments/careers/`, data)).data.data;
  },
  async getEntityAssessmentCount(_obj, data) {
    return (await axios.post(`api/assessments/student-assessments/entity-assessment-count/`, data)).data.data;
  }
  // async updateBlog(_obj, { id, data }) {
  //   return axios.patch(`api/blogs/${id}/`, data);
  // },
  // async createBlog(_obj, data) {
  //   const res = await axios.post(`api/blogs/`, data);
  //   return res.data;
  // },
  // async updateBlogStatus(_obj, { id, data }) {
  //   return axios.patch(`api/blogs/${id}/update-is-active/`, data);
  // },

  // async deleteBlog(_obj, id) {
  //   return axios.delete(`api/blogs/${id}/`);
  // },
  // async getBlogStates(_obj, id) {
  //   return (await axios.get(`api/blogs/${id}/portal-blog-states/`)).data.data.best_states;
  // }
};

export const assessment = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
