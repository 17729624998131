import store from '../store';
import { USER_ROLE_TYPES } from '../common/constants';
export async function inAppPaymentGuard(to, from, next) {
  if (['manage-plans', 'payment-plan-details', 'manage-payment-method'].includes(to.name)) {
    if (store.getters['auth/getLoggedInUser'].role_type === USER_ROLE_TYPES.STUDENT) {
      const payment = await store.dispatch('school/getinAppPaymentPlan');

      if (!payment) {
        next({ name: 'student-home' });
      } else {
        next();
      }
    } else {
      next();
    }
  }
}
