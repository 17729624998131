import axios from 'axios';

const state = {
  invalidToken: false,
  inviteData: null
};

const getters = {
  getInvalidToken: state => state.invalidToken,
  getInviteData: state => state.inviteData
};

const mutations = {
  updateInviteTokenInvalid: (state, res) => {
    state.invalidToken = res;
  },
  updateInviteData: (state, res) => {
    state.inviteData = res;
  }
};

const actions = {
  async getAllInvites(_obj, params) {
    const res = await axios.get(`api/invites/`, { params });
    return res.data;
  },

  async getInvite(_obj, id) {
    const res = await axios.get(`api/invites/${id}/`);
    return res.data;
  },

  async createInvite(_obj, data) {
    return axios.post(`api/invites/`, data);
  },

  async updateInvite(_obj, { id, data }) {
    return axios.patch(`api/invites/${id}/`, data);
  },

  async deleteInvite(_obj, id) {
    return axios.delete(`api/invites/${id}/`);
  },

  async validateInviteToken({ commit }, params) {
    const res = await axios.get(`api/invites/validate-token/`, { params });
    commit('updateInviteData', res.data.data);
    return res.data;
  },

  updateInviteTokenInvalid({ commit }, tokenInvalid) {
    commit('updateInviteTokenInvalid', tokenInvalid);
  },

  updateInviteData({ commit }, data) {
    commit('updateInviteData', data);
  },

  async resendInvite(_obj, id) {
    return axios.patch(`api/invites/${id}/resend-invite/`);
  },

  async acceptInvite(_obj, { data, params }) {
    return axios.post(`api/invites/accept-invite/`, data, { params });
  },

  async resendAllInvites(_obj, data) {
    return axios.post(`api/invites/resend-all-invites/`, data);
  }
};

export const invite = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
