import store from '../store';
import { USER_ROLE_TYPES } from '../common/constants';
export async function isStoryExistsGaurd(to, from, next) {
  if (store.getters['auth/getLoggedInUser'].role_type === USER_ROLE_TYPES.STUDENT) {
    const story = await store.dispatch('stories/getStoriesVerification');

    if (to.name === 'update-sponsorship-spotlight') {
      if (story.story_existed) {
        next();
      } else if (story.story_existed && !story.recently_posted) {
        next({ name: 'student-sponsorship-spotlight' });
      }
    } else if (to.name === 'student-sponsorship-spotlight') {
      if (story.story_existed && story.recently_posted) {
        next({ name: 'update-sponsorship-spotlight' });
      } else if (!story.recently_posted) {
        next();
      }
    }
  }
}
