import store from '../store';
import { USER_ROLES, USER_ROLE_TYPES } from '../common/constants';

export function homeGuard(to, from, next) {
  // Redirecting irrelevant roles to their home page.
  if (store.getters['auth/getLoggedInUser'].role === USER_ROLES.SUPER_ADMIN) {
    next({ name: 'admin-dashboard' });
  } else if (store.getters['auth/getLoggedInUser'].role_type === USER_ROLE_TYPES.SCHOOL) {
    next({ name: 'school-home' });
  } else if (store.getters['auth/getLoggedInUser'].role_type === USER_ROLE_TYPES.EMPLOYER) {
    next({ name: 'employer-home' });
  } else if (store.getters['auth/getLoggedInUser'].role === USER_ROLES.PARTNER) {
    next({ name: 'partner-home' });
  } else if (store.getters['auth/getLoggedInUser'].role === USER_ROLES.STUDENT) {
    next({ name: 'student-home' });
  } else if (store.getters['auth/getLoggedInUser'].role === USER_ROLES.ORGANIZATION) {
    next({ name: 'organization-home' });
  } else {
    next({ name: 'not-found' });
  }
}
