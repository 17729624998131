<template>
  <b-list-group-item class="clickable-item" :class="!notification.is_read ? 'active' : ''" @click="setNotificationRead">
    <span class="d-flex align-items-center mb-1">
      <small class="text-black-50">{{ formatNotificationTimeAgo }}</small>
      <span v-if="!notification.is_read" class="ml-auto unread-indicator bg-accent" />
    </span>
    <span class="d-flex">
      <fmv-avatar
        :src="notification.avatar"
        :title="NOTIFICATION_ICONS[notification.notification_type]"
        title-class="bg-light"
        circle
        class="mr-2"
        size="xs"
      >
        <md-icon :class="`text-${NOTIFICATION_ICONS_COLOR[notification.notification_type]}`">{{
          NOTIFICATION_ICONS[notification.notification_type]
        }}</md-icon>
      </fmv-avatar>
      <span class="flex d-flex flex-column">
        <span v-html="getNotificationMappings"></span>
      </span>
    </span>
  </b-list-group-item>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDateTime, NOTIFICATION_EXTRA_MAPPINGS, formatTimeAgo } from '../../common/utils';
import { NOTIFICATION_ICONS, NOTIFICATION_ICONS_COLOR } from '../../common/constants';
import { FmvAvatar } from 'fmv-avatar';

export default {
  name: 'NotificationListItem',
  components: { FmvAvatar },

  props: {
    notification: Object,
  },

  data() {
    return {
      NOTIFICATION_ICONS,
      NOTIFICATION_ICONS_COLOR,
      invoiceDetailModal: {
        showInvoiceDetailsModal: false,
        invoiceId: null,
        selectedTabIndex: 0,
      },
    };
  },

  computed: {
    getNotificationMappings() {
      if (this.notification.extra.new_rec_fee === 0) {
        return this.$t(`notificationMsgs.notificationTypes.partnership_rec_fee_changed_free`, this.notificationExtra);
      }
      return this.$t(
        `notificationMsgs.notificationTypes.${this.notification.notification_type}`,
        this.notificationExtra
      );
    },
    notificationExtra() {
      if (this.notification.extra.new_rec_fee === 0) {
        return NOTIFICATION_EXTRA_MAPPINGS('partnership_rec_fee_changed_free', this.notification);
      }
      return NOTIFICATION_EXTRA_MAPPINGS(this.notification.notification_type, this.notification);
    },

    formatNotificationTimeAgo() {
      return formatTimeAgo(this.notification.created_at);
    },
  },

  methods: {
    ...mapActions('notifications', ['updateNotification']),
    NOTIFICATION_EXTRA_MAPPINGS,
    formatDateTime,
    formatTimeAgo,
    async setNotificationRead() {
      this.$emit('notificationRead', this.notification);
      if (!this.notification.is_read) {
        this.notification.is_read = true;
        const data = { is_read: true };
        await this.updateNotification({ id: this.notification.id, data });
      }
    },
  },
};
</script>
