import axios from 'axios';

const state = {
  isStoryExists: {},
  storiesEmpFilters: {}
};

const getters = {
  getStoryExists: state => state.isStoryExists,
  getStoriesEmpFilters: state => state.storiesEmpFilters
};

const mutations = {
  updateStoryExists: (state, isStoryExists) => {
    state.isStoryExists = isStoryExists;
  },
  updateStoriesEmpFilters: (state, updatedFilters) => {
    state.storiesEmpFilters = { ...state.storiesEmpFilters, ...updatedFilters };
  }
};

const actions = {
  async getInquiry(_obj, params) {
    const res = await axios.get(`api/student-stories/retrieve-healthenroll-inquiry/`, { params });
    return res.data;
  },

  async createStories(_obj, data) {
    return axios.post(`api/student-stories/`, data);
  },

  async getStoriesUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/student-stories/upload-url/`, data)).data.data;
  },

  async updateStories(_obj, { id, data }) {
    return axios.patch(`api/student-stories/${id}/`, data);
  },

  async getStoriesVerification({ commit }) {
    const res = await axios.get(`api/student-stories/verification/`);
    commit('updateStoryExists', res.data.data);
    return res.data.data;
  },

  async getStories() {
    return axios.get(`api/student-stories/`);
  },

  async updateStoryVisibility(_obj, { id, data }) {
    return axios.patch(`api/student-stories/${id}/update-is-visible/`, data);
  },
  async getAllStdStories(_obj, params) {
    const res = await axios.get(`api/student-stories/`, { params });
    return res.data;
  },
  async getStdStory(_obj, id) {
    const res = await axios.get(`api/student-stories/${id}/`);
    return res.data;
  },
  async getPublicStdStories(_obj, params) {
    const res = await axios.get(`api/student-stories/public-stories-list/`, { params });
    return res.data;
  },
  async getEmpSclPartnership(_obj, id) {
    const res = await axios.get(`api/student-stories/${id}/emp-school-partnership/`);
    return res.data;
  },

  async getOrgSclPartnership(_obj, id) {
    const res = await axios.get(`api/student-stories/${id}/org-school-partnership/`);
    return res.data;
  },
  async getContact(_obj, id) {
    const res = await axios.get(`api/student-stories/${id}/story-contact-details/`);
    return res.data;
  },

  async updateStoriesEmpFilters({ commit }, { filters }) {
    commit('updateStoriesEmpFilters', filters);
  },

  async getSclEnrolledStudent(_obj, id) {
    const res = await axios.get(`api/student-stories/${id}/school-student/`);
    return res.data;
  }
};

export const stories = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
