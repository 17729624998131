import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getStudents(_obj, { data, params }) {
    return axios.post(`api/attendance/students/`, data, { params });
  },

  async markAttendance(_obj, data) {
    return axios.post(`api/attendance/`, data);
  },

  async getAttendance(_obj, params) {
    return axios.get(`api/attendance/`, params);
  },

  async updateAttendance(_obj, data) {
    return axios.put(`api/attendance/update/`, data);
  },

  // async updateStudent(_obj, { id, data }) {
  //   return axios.patch(`api/students/${id}/`, data);
  // },

  async deleteAttendance(_obj, data) {
    return axios.post(`api/attendance/delete/`, data);
  },

  async getSummary(_obj, params) {
    const res = await axios.get(`api/attendance/summary/`, { params });
    return res.data;
  },

  async viewAttendance(_obj, { data, params }) {
    return axios.post(`api/attendance/within-range/`, data, { params });
  },

  async postTag(_obj, data) {
    return axios.post(`api/attendance/tags/`, data);
  },

  async updateTag(_obj, { id, data }) {
    return axios.patch(`api/attendance/tags/${id}/`, data);
  },

  async getTags(_obj, params) {
    return axios.get(`api/attendance/tags/`, { params });
  },

  async deleteTag(_obj, id) {
    return axios.delete(`api/attendance/tags/${id}/`);
  },

  async exportAttendanceTable(_obj, data) {
    const res = await axios.post(`api/attendance/export-attendance-table/`, data);
    return res.data;
  }
};

export const attendance = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
