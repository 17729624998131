import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllCareerPreferences(_obj, params) {
    const res = await axios.get(`api/career-preferences/`, { params });
    return res.data;
  },

  async getCareerPreference(_obj, id) {
    const res = await axios.get(`api/career-preferences/${id}/`);
    return res.data;
  },

  async createCareerPreference(_obj, data) {
    return axios.post(`api/career-preferences/`, data);
  },

  async updateCareerPreference(_obj, { id, data }) {
    return axios.patch(`api/career-preferences/${id}/`, data);
  },

  async deleteCareerPreference(_obj, id) {
    return axios.delete(`api/career-preferences/${id}/`);
  },

  async getCareerPrefUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/career-preferences/upload-url/`, data)).data.data;
  },

  async getCareerPreferencesWithFav(_obj, params) {
    const res = await axios.get(`api/career-preferences/with-favorites/`, { params });
    return res.data;
  },
  async getPublicCareers(_obj, params) {
    const res = await axios.get(`api/career-preferences/public-careers/`, { params });
    return res.data;
  },
  async updateCareerOrder(_obj, data) {
    return axios.patch(`api/career-preferences/update-career-pathway-order/`, data);
  }
};

export const careerPreference = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
