<template>
  <b-nav-item-dropdown variant="flush" no-caret right class="dropdown-notifications dropdown-xs-down-full d-sm-flex">
    <template slot="button-content">
      <md-icon :class="iconClass">{{ icon }}</md-icon>
      <b-badge v-if="badge && unreadCount" pill variant="accent badge-notifications">
        {{ unreadCount }}
      </b-badge>
    </template>

    <div>
      <div class="row mt-3 ml-4 mr-3 d-flex justify-content-between">
        <h6>Wallet</h6>
        <router-link :to="{ name: 'manage-credits-history' }">
          <md-icon :class="iconClass" v-b-popover.hover.top="'Credits History'">history</md-icon>
        </router-link>
      </div>

      <div class="my-4">
        <h6 class="text-center">Available Tokens</h6>
        <h4 class="text-center">
          <i class="fas fa-coins clickable-item" v-b-popover.hover.top title="Tokens"></i> {{ $n(getAvailableTokens) }}
          <span class="ml-2">
            <i class="fas fa-briefcase mr-1 clickable-item" v-b-popover.hover.top title="Job Posting"> </i
            >{{ getAvailableJobCredits }}</span
          >
        </h4>
      </div>
      <div class="d-flex justify-content-center mb-4" v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER">
        <router-link :to="{ name: 'manage-purchase-tokens' }" class="btn btn-primary">Purchase Tokens</router-link>
      </div>
    </div>
  </b-nav-item-dropdown>
</template>

<i18n>
  {
    "en": {
      "view_all": "View {title}"
    },
    "ro": {
      "view_all": "Vezi {title}",
      "Messages": "Mesaje"
    }
  }
</i18n>

<script>
import routeToMixin from '@/mixins/route-to';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
export default {
  name: 'NavWallet',
  components: {},
  mixins: [routeToMixin],
  props: {
    badge: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'account_balance_wallet',
    },
    iconClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default() {
        return 'Wallet';
      },
    },
    routeMessages: {
      type: [String, Object],
    },
  },
  data() {
    return {
      isLoading: false,
      USER_ROLES,
      USER_ROLE_TYPES,
    };
  },
  methods: {
    ...mapActions('credit', ['getEmployerAvailableTokens', 'updateStateAvailTokens', 'getOrgAvailableTokens']),

    async fetchAvailableTokens() {
      this.isLoading = true;

      await this.updateStateAvailTokens();

      this.isLoading = false;
    },
  },

  mounted() {
    this.fetchAvailableTokens();
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('credit', ['getAvailableTokens', 'getAvailableJobCredits']),
  },
};
</script>
