import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getBestStateOptions(_obj, params) {
    const res = await axios.get(`api/locations/best-school-state/`, { params });
    return res.data;
  },

  async getBestCitiesOptions(_obj, data) {
    const res = await axios.post(`api/locations/best-school-state/best-states-cities/`, data);
    return res.data;
  },

  async getCityPresignedUrl(_obj, data) {
    return (await axios.post(`api/locations/best-school-city/image-upload-url/`, data)).data.data;
  },

  async getBestSchoolCity(_obj, id) {
    const res = await axios.get(`api/locations/best-school-city/${id}/`);
    return res.data;
  },

  async createBestSchoolState(_obj, data) {
    return axios.post(`api/locations/best-school-state/`, data);
  },

  async updateBestSchoolState(_obj, { id, data }) {
    return axios.patch(`api/locations/best-school-state/${id}/`, data);
  },

  async createBestSchoolCity(_obj, data) {
    return axios.post(`api/locations/best-school-city/`, data);
  },

  async updateBestSchoolCity(_obj, { id, data }) {
    return axios.patch(`api/locations/best-school-city/${id}/`, data);
  },

  async getAllStates(_obj, params) {
    const res = await axios.get(`api/locations/best-school-state/`, { params });
    return res.data;
  },

  async getBestSchoolState(_obj, id) {
    const res = await axios.get(`api/locations/best-school-state/${id}/`);
    return res.data;
  },

  async getStatePresignedUrl(_obj, data) {
    return (await axios.post(`api/locations/best-school-state/image-upload-url/`, data)).data.data;
  },

  async getAllCities(_obj, params) {
    const res = await axios.get(`api/locations/best-school-city/`, { params });
    return res.data;
  },

  async deleteState(_obj, id) {
    return axios.delete(`api/locations/best-school-state/${id}/`);
  },

  async deleteCity(_obj, id) {
    return axios.delete(`api/locations/best-school-city/${id}/`);
  },

  async updateStateStatus(_obj, { id, data }) {
    return axios.patch(`api/locations/best-school-state/${id}/state-status-update/`, data);
  },

  async updateCityStatus(_obj, { id, data }) {
    return axios.patch(`api/locations/best-school-city/${id}/city-status-update/`, data);
  },
  async getBestSchoolCityByName(_obj, params) {
    const res = await axios.get(`api/locations/best-school-city/job-state-cities/`, { params });
    return res.data;
  },
  async getAllHighSchoolsByState(_obj, params) {
    const res = await axios.get(`api/locations/best-school-city/all-active-cities/`, { params });
    return res.data;
  },
  async getAllHighSchoolUnis(_obj, params) {
    const res = await axios.get(`api/locations/best-school-city/school-partnered-cities/`, { params });
    return res.data;
  }
};

export const location = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
