import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllPrograms(_obj, params) {
    const res = await axios.get(`api/programs/`, { params });
    return res.data;
  },

  async getAllPublicPrograms(_obj, params) {
    const res = await axios.get(`api/programs/all-programs/`, { params });
    return res.data;
  },

  async getProgram(_obj, id) {
    const res = await axios.get(`api/programs/${id}/`);
    return res.data;
  },

  async createProgram(_obj, data) {
    return axios.post(`api/programs/`, data);
  },

  async getProgramImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/programs/upload-url/`, data)).data.data;
  },

  async updateProgram(_obj, { id, data }) {
    return axios.patch(`api/programs/${id}/`, data);
  },

  async deleteProgram(_obj, id) {
    return axios.delete(`api/programs/${id}/`);
  },

  async getPublicSchoolPrograms(_obj, params) {
    const res = await axios.get(`api/programs/public-school-programs/`, { params });
    return res.data;
  },
  async getAllPortalPrograms(_obj, params) {
    const res = await axios.get(`api/programs/portal-all-programs/`, { params });
    return res.data;
  },
  async getSclPrograms(_obj, params) {
    const res = await axios.get(`api/programs/school-programs/`, { params });
    return res.data;
  }
};

export const program = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
