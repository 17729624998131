<template>
  <b-avatar
    :src="isLoading ? null : fileUrl || (user ? user[urlField] : null)"
    :text="
      !hideText
        ? isLoading
          ? '...'
          : text ||
            (user && user[firstNameField] && user[lastNameField]
              ? `${user[firstNameField][0]}${user[lastNameField][0]}`
              : '')
        : ''
    "
    :rounded="rounded"
    :size="size"
    :variant="variant"
    :class="avatarClass"
  ></b-avatar>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    user: Object,
    fileUrl: { type: String, required: false },
    urlField: { type: String, default: () => 'avatar_url' },
    firstNameField: { type: String, default: () => 'first_name' },
    lastNameField: { type: String, default: () => 'last_name' },
    rounded: { type: String || Boolean, required: false },
    size: { type: String, required: false },
    variant: { type: String, default: () => 'primary' },
    isLoading: { type: Boolean, default: false },
    text: { type: String, required: false },
    hideText: { type: Boolean, default: false },
    avatarClass: { type: String, required: false },
  },
};
</script>

<style>
</style>
