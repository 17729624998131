import store from '../store';
import { get } from 'lodash';

export async function studentOnboardGuard(to, from, next) {
  // Redirecting students with incomplete profiles to their onboarding page.

  let goNext = true;

  if (to.name !== 'student-onboarding') {
    if (!get(store.getters['student/getStudentProfile'], 'is_profile_complete')) {
      const res = await store.dispatch('student/getMyStudentProfile', true);
      if (!get(res, 'is_profile_complete')) {
        next({ name: 'student-onboarding' });
        goNext = false;
      }
    }
  } else if (get(store.getters['student/getStudentProfile'], 'is_profile_complete')) {
    next({ name: 'student-home' });
    goNext = false;
  } else {
    const res = await store.dispatch('student/getMyStudentProfile', true);
    if (get(res, 'is_profile_complete')) {
      next({ name: 'student-home' });
      goNext = false;
    }
  }

  if (goNext) {
    next();
  }
}
