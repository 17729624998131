const _ = require('lodash');

const state = () => ({
  ui: true,
  state: {
    layout: {
      layout: 'blank',
      rtl: false
    },
    'app::mainDrawer': {
      align: 'start',
      sidebar: 'dark'
    },
    'fixed::mainDrawer': {
      align: 'start',
      sidebar: 'dark'
    },
    'sticky::mainDrawer': {
      align: 'start',
      sidebar: 'dark'
    },
    'boxed::mainDrawer': {
      align: 'start',
      sidebar: 'light'
    },
    'app::mainNavbar': {
      navbar: 'light'
    },
    'fixed::mainNavbar': {
      navbar: 'light'
    },
    'sticky::mainNavbar': {
      navbar: 'light'
    },
    'boxed::mainNavbar': {
      navbar: 'dark'
    }
  }
});

const mutations = {
  SET_SETTINGS(state, settings) {
    if (settings) {
      state.state = _.merge({}, state.state, settings);
    }
  }
};

const actions = {
  setSettings({ commit }, settings) {
    commit('SET_SETTINGS', settings);
  }
};

const getters = {
  layout: state => state.state.layout.layout,
  routes: (state, getters) => {
    const layout = getters.layout;
    return {
      changePassword: {
        name: 'change-password',
        params: { layout }
      },
      editAccountProfile: {
        name: 'layout-edit-account-profile',
        params: { layout }
      },
      editAccount: {
        name: 'layout-edit-account',
        params: { layout }
      },

      forgotPassword: {
        name: 'forgot-password',
        params: { layout }
      },

      home: {
        name: 'home',
        params: { layout }
      },

      instructorDashboard: {
        name: 'layout-instructor-dashboard',
        params: { layout }
      },

      instructorProfile: {
        name: 'layout-instructor-profile',
        params: { layout }
      },

      login: {
        name: 'login',
        params: { layout }
      },
      logout: {
        name: 'logout',
        params: { layout }
      },

      studentDashboard: {
        name: 'layout-student-dashboard',
        params: { layout }
      },

      studentProfile: {
        name: 'layout-student-profile',
        params: { layout }
      },
      students: {
        name: 'students',
        params: { layout }
      },
      jobs: {
        name: 'jobs',
        params: { layout }
      },
      events: {
        name: 'events',
        params: { layout }
      }
    };
  }
};

export const settings = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
