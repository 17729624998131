import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getInquiries(_obj, params) {
    const res = await axios.get(`api/inquiries/`, { params });
    return res.data;
  },

  async getInquiry(_obj, id) {
    const res = await axios.get(`api/inquiries/${id}/`);
    return res.data.data;
  }
};

export const inquiries = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
