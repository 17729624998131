import axios from 'axios';

const state = {
  uniFilters: {},
};

const getters = {
  getUniFilters: state => state.uniFilters,
};

const mutations = {
  updateUniFilters: (state, updatedFilters) => {
    state.uniFilters = { ...state.uniFilters, ...updatedFilters };
  },
};

const actions = {
  async getAllUnis(_obj, params) {
    const res = await axios.get(`api/universities/`, { params });
    return res.data;
  },

  async getUni(_obj, id) {
    const res = await axios.get(`api/universities/${id}/`);
    return res.data;
  },

  async getUniFromSlug(_obj, slug) {
    const res = await axios.get(`api/universities/public-university-retrieve/`, { params: { slug } });
    return res.data;
  },

  async createUni(_obj, data) {
    return axios.post(`api/universities/`, data);
  },

  async updateUni(_obj, { id, data }) {
    return axios.patch(`api/universities/${id}/`, data);
  },

  async deleteUni(_obj, id) {
    return axios.delete(`api/universities/${id}/`);
  },

  async getUniImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/universities/image-upload-url/`, data)).data.data;
  },

  async getAllPartneredUniversities(_obj, params) {
    const res = await axios.get(`api/universities/school-partnered-universities/`, { params });
    return res.data;
  },

  async getAllUniversitiesForStd(_obj, params) {
    const res = await axios.get(`api/universities/student-portal-universities/`, { params });
    return res.data;
  },

  async getAllUniversitiesByState(_obj, params) {
    const res = await axios.get(`api/universities/public-university-list/`, { params });

    return res.data;
  },

  async getAllUniversities(_obj, params) {
    const res = await axios.get(`api/universities/student-university-list/`, { params });
    return res.data;
  },

  async getFavUniversities(_obj, params) {
    const res = await axios.get(`api/universities/student-favourite-list/`, { params });
    return res.data;
  },

  async updateFavStatus(_obj, data) {
    return axios.patch(`api/universities/favourite-status/`, data);
  },

  async updateUniFilters({ commit }, { filters }) {
    commit('updateUniFilters', filters);
  },

  async getMinMaxTuition(_obj, params) {
    const res = await axios.get(`api/universities/min-max-tuition-fee/`, { params });
    return res.data;
  },
};

export const uni = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
