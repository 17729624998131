import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};
const actions = {
  async updateEmailSubscriptionStatus(_obj, data) {
    return (await axios.post(`api/email-subscription/update-status/`, data)).data.data;
  }
};

export const emailSubscription = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
