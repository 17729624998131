import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllTestimonials(_obj, params) {
    const res = await axios.get(`api/testimonials/`, { params });
    return res.data;
  },

  async getTestimonial(_obj, id) {
    const res = await axios.get(`api/testimonials/${id}/`);
    return res.data;
  },

  async createTestimonial(_obj, data) {
    return axios.post(`api/testimonials/`, data);
  },

  async updateTestimonialStatus(_obj, { id, data }) {
    return axios.patch(`api/testimonials/${id}/testimonial-update-status/`, data);
  },

  async getPublicTestimonials(_obj, params) {
    const res = await axios.get(`api/testimonials/get-public-testimonials/`, { params });
    return res.data;
  },
  async verifyTestimonial(_obj, params) {
    const res = await axios.get(`api/testimonials/verify-testimonial/`, { params });
    return res.data;
  }
};

export const testimonial = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
