import store from '../store';
import { FORGOT_PASS_TOAST_OPTIONS } from '../common/constants';

export async function validateResetPasswordToken(to, from, next) {
  if (!store.getters['auth/isLoggedIn']) {
    try {
      await store.dispatch('auth/validateResetPasswordToken', {
        token: to.query.token || ''
      });
      next();
    } catch (error) {
      store.dispatch('auth/updateResetPasswordTokenInvalid', FORGOT_PASS_TOAST_OPTIONS.INVALID_TOKEN);
      next({ name: 'login' });
    }
  } else next({ name: 'home' });
}
