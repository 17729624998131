import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllHires(_obj, params) {
    const res = await axios.get(`api/hires/`, { params });
    return res.data;
  },

  async getHire(_obj, id) {
    const res = await axios.get(`api/hires/${id}/`);
    return res.data;
  },

  async getStudentHireDetails(_obj, params) {
    const res = await axios.get(`api/hires/student-hire-details/`, { params });
    return res.data;
  },

  async createHire(_obj, data) {
    const res = await axios.post(`api/hires/`, data);
    return res.data;
  },

  async deleteHire(_obj, { id, data }) {
    return axios.patch(`api/hires/${id}/end-contract/`, data);
  },
  async hirePaymentSuccess(_obj, params) {
    const res = await axios.get(`api/hires/success-payment-method/`, { params });
    return res.data;
  },
  async hirePaymentCancel(_obj, params) {
    const res = await axios.get(`api/hires/failure-payment-method/`, { params });
    return res.data;
  },

  async getStudentHireStatus(_obj, params) {
    const res = await axios.get(`api/hires/hiring-status/`, { params });
    return res.data;
  }
};

export const hire = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
