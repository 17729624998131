<template>
  <sticky-layout
    :body-class="bodyClass"
    :container-class="containerClass"
    :drawer-align="mainDrawerAlign"
    :sidebar-type="sidebarType"
    :sidebar-variant="sidebarVariant"
    sidebar-search-form-class="search-form--black"
    :sidebar-menu="$store.getters['sidebarMenuConfig/layoutMenu']"
    :header-class="headerClass"
    :sub-layout="subLayout"
    :sub-layout-has-scrolling-region="subLayoutHasScrollingRegion"
    :sub-layout-drawer="subLayoutDrawer"
    :sub-layout-drawer-id="subLayoutDrawerId"
    :sub-layout-drawer-align="subLayoutDrawerAlign"
    :navbar-type="navbarType"
    :navbar-variant="navbarVariant"
    :navbar-container-class="navbarContainerClass"
    :navbar-brand="$store.state.brand"
    :navbar-full-sample-guest-mode="!isLoggedIn"
    :footer-brand="$store.state.brand"
  >
    <!-- Replace Navbar Component -->
    <template v-slot:navbar>
      <!-- <div class="navbar-height bg-white px-3">Replace Navbar Component</div> -->
      <app-navbar
        data-primary
        :navbar-type="navbarType"
        :navbar-variant="navbarVariant"
        :navbar-brand="$store.state.brand"
        :navbar-container-class="navbarContainerClass"
        navbar-toggle-class="sidebar-toggle-menu"
      >
        <slot name="navbar-content" />
      </app-navbar>
    </template>

    <!-- Replace Navbar Content -->
    <!-- <template v-slot:navbar-content> Replace Navbar Content </template> -->

    <!-- Replace Sidebar Component -->
    <template v-slot:sidebar>
      <app-sidebar
        :sidebar-search="false"
        sidebar-search-form-class="search-form--black"
        :sidebar-brand="false"
        :sidebar-type="sidebarType"
        :sidebar-variant="sidebarVariant"
        :sidebar-menu="$store.getters['sidebarMenuConfig/layoutMenu']"
        class="sidebar-p-t"
      >
        <template v-slot:default="{ sidebar }">
          <slot name="sidebar-content" v-bind:sidebar="sidebar" />
        </template>
      </app-sidebar>
    </template>

    <!-- Replace Sidebar Content -->
    <!-- <template v-slot:sidebar-content="{sidebar}">
      <div class="sidebar-heading">Replace Sidebar Content</div>
      <pre
        class="sidebar-text"
        v-text="sidebar.menu" />
    </template> -->

    <!-- Page Content -->
    <template v-slot:default="{ layoutProps }">
      <router-view v-bind:layout-props="layoutProps" />
    </template>

    <!-- Footer Content -->
    <template v-slot:footer-content="footerProps">
      <div
        v-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL"
        class="text-70 school-footer"
        :class="isMobSmallScreen ? 'mb-5' : ''"
      >
        <div :class="isMobSmallScreen ? 'text-center' : 'd-flex align-items-center justify-content-center'">
          <div :class="isMobSmallScreen ? 'mb-3' : 'mr-5'" v-if="get(getCurrentSchool, 'phone')">
            <md-icon class="mb-1 school-icon mr-1">local_phone</md-icon>
            <a :href="`tel:${get(getCurrentSchool, 'phone')}`">{{ get(getCurrentSchool, 'phone') }} </a>
          </div>
          <div :class="isMobSmallScreen ? 'mb-3' : 'mr-5'" v-if="get(getCurrentSchool, 'contact_email')">
            <md-icon class="mb-1 school-icon mr-1">email</md-icon>
            <a :href="`mailto:${get(getCurrentSchool, 'contact_email')}`">Email</a>
          </div>

          <div v-if="get(getCurrentSchool, 'website_url')">
            <md-icon class="mb-1 school-icon mr-1">language</md-icon>
            <a target="_blank" :href="get(getCurrentSchool, 'website_url')">Our Website</a>
          </div>
        </div>
        <div class="mt-4 text-center">
          <a
            :href="`http://maps.google.com/?q=1200${get(getCurrentSchool, 'name')}, ${get(
              getCurrentSchool,
              'address'
            )}, ${get(getCurrentSchool, 'city')}, ${get(getCurrentSchool, 'state')}`"
            target="_blank"
          >
            {{ get(getCurrentSchool, 'name') }}
          </a>
        </div>
        <div
          v-if="
            get(getCurrentSchool, 'address') &&
            get(getCurrentSchool, 'city') &&
            get(getCurrentSchool, 'state') &&
            get(getCurrentSchool, 'zipcode') &&
            get(getCurrentSchool, 'country')
          "
          class="mt-2 text-center"
        >
          <md-icon class="mb-1 school-icon">location_on</md-icon>
          <a
            :href="`http://maps.google.com/?q=1200${get(getCurrentSchool, 'name')}, ${get(
              getCurrentSchool,
              'address'
            )}, ${get(getCurrentSchool, 'city')}, ${get(getCurrentSchool, 'state')}`"
            target="_blank"
          >
            {{ get(getCurrentSchool, 'address') }}, {{ get(getCurrentSchool, 'city') }},
            {{ get(getCurrentSchool, 'state') }} {{ get(getCurrentSchool, 'zipcode') }},
            {{ get(getCurrentSchool, 'country') }}
          </a>
        </div>
        <div class="mt-5 text-center">
          <strong class="small mr-2">POWERED BY</strong>
          <img
            class="brand-icon"
            src="@/assets/images/logos/transition_connect_tp.png"
            width="150"
            :alt="footerProps.footerBrand"
          />
        </div>
      </div>
      <div v-else>
        <p class="text-70 brand mb-24pt">
          <strong class="text-50 small mr-2">POWERED BY</strong>
          <img
            class="brand-icon"
            src="@/assets/images/logos/logo_blue.png"
            width="150"
            height="auto"
            :alt="footerProps.footerBrand"
          />

          <span
            class="ml-auto"
            v-if="
              !isMobSmallScreen &&
              get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER &&
              get(getLoggedInUser.linked_entity, 'organization.logo_url')
            "
          >
            <strong class="text-50 small mr-2"> PARTNERED WITH </strong>

            <img
              :src="get(getLoggedInUser.linked_entity, 'organization.logo_url')"
              alt=""
              style="width: auto; height: 60px"
            />
          </span>
        </p>

        <div
          v-if="
            isMobSmallScreen &&
            get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER &&
            get(getLoggedInUser.linked_entity, 'organization.logo_url')
          "
          class="mb-2"
        >
          <strong class="text-50 small ml-auto mr-2"> PARTNERED WITH </strong>

          <img
            :src="get(getLoggedInUser.linked_entity, 'organization.logo_url')"
            alt=""
            style="width: auto; height: 50px"
          />
        </div>

        <p class="mb-8pt d-flex">
          <a class="text-70 text-underline mr-8pt small">Terms</a>
          <a class="text-70 text-underline small">Privacy</a>
        </p>

        <p class="text-50 measure-lead-max small">
          Copyright {{ footerProps.copyrightYear }} &copy; All rights reserved!
        </p>
      </div>
    </template>
  </sticky-layout>
</template>

<script>
import StickyLayout from '@/components/Layout/StickyLayout.vue';
// import AppSettings from '@/components/Settings';
import { get } from 'lodash';
import { mapGetters } from 'vuex';

import mainDrawerMixin from '@/mixins/main-drawer';
import navbarConfigMixin from '@/mixins/navbar-config';
import sidebarConfigMixin from '@/mixins/sidebar-config';
import layoutConfigMixin from '@/mixins/layout-config';
import subLayoutMixin from '@/mixins/sub-layout';
import { USER_ROLE_TYPES } from '@/common/constants';

import AppNavbar from '@/components/Navbar/Navbar.vue';
import AppSidebar from '@/components/Sidebar/Sidebar.vue';

export default {
  components: {
    StickyLayout,
    AppNavbar,
    AppSidebar,
    // AppSettings,
  },
  mixins: [mainDrawerMixin, navbarConfigMixin, sidebarConfigMixin, layoutConfigMixin, subLayoutMixin],
  data() {
    return {
      headerClass: 'mb-0',
      windowWidth: window.innerWidth,
      USER_ROLE_TYPES,
    };
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),
    bodyClass() {
      return null;
    },
    isMobSmallScreen() {
      return this.windowWidth <= 576;
    },
    sidebarConfigKey() {
      return this.mainDrawerSidebar;
    },
  },
  methods: {
    get,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  created() {
    this.$root.$on('luma::headerClass', (headerClass) => {
      if (headerClass !== undefined) {
        this.headerClass = headerClass;
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss">
@media (min-width: 993px) {
  .sidebar-toggle-menu.d-block {
    display: none !important;
  }
}
</style>