<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  head() {
    return {
      title: get(this, 'completeHeadTitle', `${this.$t(this.title)} - Transition`),
    };
  },
  data() {
    return {
      title: null,
    };
  },
};
</script>
