import axios from 'axios';
import { get } from 'lodash';

const state = {
  dark: {
    type: 'dark-pickled-bluewood',
    variant: 'navbar-shadow',
  },
  light: {
    type: 'light',
    variant: 'white border-bottom-2',
  },
  navbarBrandLogo: null,
  navbarBrandText: 'Transition',
  orgReferId: null,
};

const getters = {
  getNavbarBrandLogo: state => state.navbarBrandLogo,
  getNavbarBrandText: state => state.navbarBrandText,
  getOrgReferId: state => state.orgReferId,
};

const mutations = {
  updateNavbarBrandDetails: (state, { logo, brandText }) => {
    state.navbarBrandLogo = logo;
    state.navbarBrandText = brandText;
  },
  updateOrgReferId: (state, id) => {
    state.orgReferId = id;
  },
};

const actions = {
  async updateNavbarBrandDetails({ commit }, linkedEntity = null) {
    if (!linkedEntity) {
      let resp;
      if (window.location.host !== process.env.VUE_APP_MAIN_DOMAIN) {
        resp = await axios.get(`api/subdomains/get-domain-entity/?url=${window.location.host}`);
        linkedEntity = get(resp.data, 'data');
      } else {
        resp = await axios.get('api/users/my-linked-entity/');
        linkedEntity = get(resp.data, 'data.linked_entity');
      }
    }

    if (linkedEntity) {
      commit('auth/updateMyProfile', { linked_entity: linkedEntity }, { root: true });
      commit('updateNavbarBrandDetails', {
        logo: linkedEntity.logo_url,
        brandText: linkedEntity.name,
      });
      if (linkedEntity.org_refer_id) {
        commit('updateOrgReferId', linkedEntity.org_refer_id);
      }
    } else {
      commit('updateNavbarBrandDetails', {
        logo: null,
        brandText: 'Transition',
      });
    }

    return linkedEntity;
  },

  resetNavbarBrandDetails({ commit }) {
    commit('updateNavbarBrandDetails', {
      logo: null,
      brandText: 'Transition',
    });
  },
};

export const navbarConfig = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
