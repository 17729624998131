import axios from 'axios';

const state = {
  jobFilters: {
    zipCode: null,
    zipCodeRange: 30,

    experience: null,
    certifications: [],
    records: [],

    type: [],
    schedule: []
  },
  resetJobFilters: false
};

const getters = {
  getJobFilters: state => state.jobFilters,
  getResetJobFilters: state => state.resetJobFilters
};

const mutations = {
  updateJobFilters: (state, updatedFilters) => {
    state.jobFilters = { ...state.jobFilters, ...updatedFilters };
  },
  setResetJobFilters: (state, value) => {
    state.resetJobFilters = value;
  }
};

const actions = {
  async getAllJobs(_obj, params) {
    const res = await axios.get(`api/jobs/`, { params });
    return res.data;
  },

  async getJob(_obj, id) {
    const res = await axios.get(`api/jobs/${id}/`);
    return res.data;
  },

  async createJob(_obj, data) {
    return axios.post(`api/jobs/`, data);
  },

  async updateJob(_obj, { id, data }) {
    return axios.patch(`api/jobs/${id}/`, data);
  },

  async deleteJob(_obj, id) {
    return axios.delete(`api/jobs/${id}/`);
  },

  async getJobImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/jobs/image-upload-url/`, data)).data.data;
  },

  async getJobStudentCounts(_obj, queryData) {
    return (await axios.post(`api/jobs/get-students-count/`, queryData)).data.data;
  },

  async updateJobFilters({ commit }, { filters }) {
    commit('updateJobFilters', filters);
  },

  async setResetJobFilters({ commit }, value) {
    commit('setResetJobFilters', value);
  },
  async createCloneJob(_obj, params) {
    const res = await axios.get(`api/jobs/clone-job/`, { params });
    return res.data;
  }
};

export const job = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
