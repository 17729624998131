import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getScholarshipImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/scholarships/image-upload-url/`, data)).data.data;
  },

  async getScholarship(_obj, id) {
    const res = await axios.get(`api/scholarships/${id}/retreive-portal-scholarships/`);
    return res.data;
  },

  async getScholarshipById(_obj, id) {
    const res = await axios.get(`api/scholarships/${id}/`);
    return res.data;
  },

  async createScholarship(_obj, data) {
    return axios.post(`api/scholarships/`, data);
  },

  async getAllScholarship(_obj, params) {
    const res = await axios.get(`api/scholarships/`, { params });
    return res.data;
  },

  async getAllScholarshipList(_obj, { id, params }) {
    const res = await axios.get(`api/scholarships/scholarships-list/?school=${id}`, { params });
    return res.data;
  },

  async updateScholarship(_obj, { id, data }) {
    return axios.patch(`api/scholarships/${id}/`, data);
  },

  async deleteScholarship(_obj, id) {
    return axios.delete(`api/scholarships/${id}/`);
  },

  async getAllNewScholarships(_obj, data) {
    const res = await axios.post(`api/scholarships/program-new-student-scholarships/`, data);
    return res.data;
  },
  async getAppliedScholarshipsList(_obj, data) {
    const res = await axios.post(`api/application-forms/scholarship-application/student-applied/`, data);
    return res.data;
  },

  async getAllAppliedScholarships() {
    const res = await axios.get(`api/application-forms/scholarship-application/student-applications/`);
    return res.data;
  }
};

export const scholarships = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
