export const USER_ROLES = {
  SUPER_ADMIN: 'super_admin',
  STUDENT: 'student',
  SCHOOL_OWNER: 'school_owner',
  SCHOOL_ADMIN: 'school_admin',
  EMPLOYER_ADMIN: 'employer_admin',
  EMPLOYER_CAREER_SPECIALIST: 'employer_career_specialist',
  PARTNER: 'job_partner_admin',
  ORGANIZATION: 'organization_admin',
};

export const USER_ROLE_TYPES = {
  SUPER_ADMIN: 'super_admin',
  SCHOOL: 'school',
  STUDENT: 'student',
  EMPLOYER: 'employer',
  PARTNER: 'partner',
  ORGANIZATION: 'organization',
};

export const DASHBOARD_TYPES = {
  SCHOOL: 'school',
  CARECAMPUS: 'carecampus',
  ORGANIZATION: 'organization',
};

export const ENTITY_TYPES = {
  ORGANIZATION: 'organization',
  UNIVERSITY: 'university',
  DISTRICT: 'bestschoolcity',
};

export const FORGOT_PASS_TOAST_OPTIONS = {
  INVALID_TOKEN: 'token_invalid',
  RESET_SUCCESSFUL: 'reset_successful',
};

export const LANG_ABBREVIATIONS = {
  EN: 'en',
  DE: 'de',
};

export const PASSWORD_VALIDATIONS = {
  PASSWORDS_NOT_MATCH: 'passwords_not_matched',
  PASSWORDS_MIN_LENGTH: 'password_less_than_min_length',
  PASSWORDS_ALL_NUMERIC: 'password_all_numbers',
  INCORRECT_CURR_PASS: 'incorrect_curr_pass',
};

export const PASSWORD_ALL_NUMERIC_REGEX = /^\d+$/;
export const CNA_LICENSE_NUM_REGEX = /^CN\d{10}$/;
export const PHONE_FORMAT_REGEX = /^\(\d{3}\) \d{3} - \d{4}$/;

export const DEFAULT_PAGE_SIZE = 15;

export const PAYMENT_TYPES = {
  STRIPE_SUBSCRIPTION: 'product_subscription',
  REGISTRATION: 'student_registration',
  PRODUCT_PURCHASE: 'product_purchase',
  PROCESSING_FEE: 'processing_fee',
  CLASS_PLAN: 'class_plan',
};
export const PAYMENT_PLANS = {
  PAY_NOW: 'pay_now',
  PAY_LATER: 'pay_later',
  FREE_PLAN: 'free_plan',
};

export const CUSTOMER_PORTAL_TYPES = {
  PAYMENT_PRODUCT_LINK: 'payment_product_link',
  CUSTOMER_PORTAL_LINK: 'customer_portal_link',
};

export const CAREER_PREF_CATEGORIES = [
  {
    key: 'clinical',
    name: 'Clinical Roles',
    icon: 'healing',
    description:
      'Clinical roles often have face-to-face contact with patients for the purpose of diagnosis, treatment, and ongoing care.',
  },
  {
    key: 'administrative',
    name: 'Administrative Roles',
    icon: 'assignment',
    description:
      'Adminstrative roles often referred to as health managers, are professionals in charge of the healthcare facility operations.',
  },
  {
    key: 'technology',
    name: 'Technology Roles',
    icon: 'insert_chart',
    description:
      'Health information technology (health IT) specialists handle the technical aspects of managing patient health information.',
  },
];

export const APP_DARK_COLOR = '#214e6f';

export const JOB_BENEFITS_OPTONS = [
  { value: 'healthcare_insurance', text: 'Healthcare Insurance' },
  { value: 'tuition_reimbursement', text: 'Tuition Reimbursement' },
  { value: 'childcare', text: 'Childcare' },
  { value: 'paid_mileage', text: 'Paid Mileage' },
  { value: 'other', text: 'Other' },
];

export const JOB_CERT_OPTIONS = [
  { value: 'cna', text: 'CNA', fullText: 'CNA' },
  { value: 'cpr', text: 'CPR', fullText: 'CPR Certification' },
  { value: 'bls', text: 'BLS', fullText: 'BLS Certification (Life Support)' },
  { value: 'first_aid', text: 'First Aid', fullText: 'First Aid Certification' },
  { value: 'other', text: 'Other', fullText: 'Other' },
];

export const JOB_RECORD_OPTIONS = [
  { value: 'covid_vac', text: 'Covid Vaccination' },
  { value: 'vehicle_reg', text: 'Vehicle Registration' },
  { value: 'tb_test', text: 'TB Skin Test' },
  { value: 'other', text: 'Other' },
];
export const JOB_TYPES = {
  STANDARD: 'standard',
  LOCAL: 'local',
  EXTERNAL: 'external',
};

export const JOB_STATUS = {
  ACTIVE: 'active',
  REVOKE: 'revoke',
};

export const INSTITUTION_TYPE_OPTIONS = [
  { value: 'technical_college', text: 'Community/Technical College' },
  { value: 'high_school', text: 'High School' },
  { value: 'state_training', text: 'State Based' },
  { value: 'university', text: 'University' },
  { value: 'independent', text: 'Independent' },
  { value: 'career_center', text: 'Career Center' },
  { value: 'non_profit', text: 'Non Profit' },
  { value: 'government', text: 'Government' },
  { value: 'employer_based', text: 'Employer Based' },
  { value: 'other', text: 'Other' },
];

export const JOB_TYPE_OPTIONS = [
  { value: 'full_time', text: 'Full-time' },
  { value: 'part_time', text: 'Part-time' },
  { value: 'contract', text: 'Contract' },
];

export const JOB_SCHEDULE_OPTIONS = [
  { value: 'day', text: 'Morning' },
  { value: 'evening', text: 'Evening' },
  { value: 'overnight', text: 'Overnight' },
];

export const JOB_EXP_OPTIONS = [
  { value: '0-1', text: '0-1 year' },
  { value: '1-2', text: '1-2 years' },
  { value: '3-4', text: '3-4 years' },
  { value: '5-6', text: '5-6 years' },
  { value: '7+', text: '7+ years' },
];

export const EMPLOYER_COMP_SIZE_OPTIONS = [
  { value: '0-1', text: '0-1 employees' },
  { value: '2-10', text: '2-10 employees' },
  { value: '11-50', text: '11-50 employees' },
  { value: '51-200', text: '51-200 employees' },
  { value: '201-500', text: '201-500 employees' },
  { value: '501-1000', text: '501-1,000 employees' },
  { value: '1001-5000', text: '1,001-5,000 employees' },
  { value: '5001-10000', text: '5,001-10,000 employees' },
  { value: '10001+', text: '10,001+ employees' },
];

export const EMPLOYER_COMP_TYPE_OPTIONS = [
  { value: 'adult_day_care', text: 'Adult day care' },
  { value: 'assisted_living', text: 'Assisted living' },
  { value: 'hospital', text: 'Hospital' },
  { value: 'hospice_care', text: 'Hospice care' },
  { value: 'home_care', text: 'Home care' },
  { value: 'longterm_care', text: 'Long-term care' },
];

export const EVENT_TYPES = {
  ONSITE: 'onsite',
  VIRTUAL: 'virtual',
};

export const EVENT_TYPE_OPTIONS = [
  { value: EVENT_TYPES.ONSITE, text: 'Onsite' },
  { value: EVENT_TYPES.VIRTUAL, text: 'Virtual' },
];

export const RESUME_EDUCATION_TYPE_OPTIONS = [
  { value: 'vocational', text: 'Vocational' },
  { value: 'high_school', text: 'High School' },
  { value: 'high_school_eq', text: 'High School Equivalency' },
  { value: 'certificate', text: 'Certificate' },
  { value: 'associate', text: 'Associate' },
  { value: 'bachelors', text: "Bachelor's" },
  { value: 'master', text: 'Master' },
  { value: 'doctorate', text: 'Doctorate' },
];

export const ADVISOR_EDUCATION_TYPE_OPTIONS = [
  { value: 'vocational', text: 'Vocational' },
  { value: 'high_school', text: 'High School' },
  { value: 'high_school_eq', text: 'High School Equivalency' },
  { value: 'certificate', text: 'Certificate' },
  { value: 'associate', text: 'Associate' },
  { value: 'bachelors', text: "Bachelor's" },
  { value: 'master', text: 'Master' },
  { value: 'doctorate', text: 'Doctorate' },
];

export const RESUME_SKILL_OPTIONS = [
  { value: 'patent_care_safety', text: 'Patient Care & Safety' },
  { value: 'diagnostic_testing', text: 'Diagnostic Testing' },
  { value: 'medical_terminology', text: 'Medical Terminology' },
  { value: 'electronic_medical_records', text: 'Electronic Medical Records' },
  { value: 'vital_signs_patient_monitoring', text: 'Vital Signs & Patient Monitoring' },
  { value: 'medication_administration', text: 'Medication Administration' },
  { value: 'privacy_hipaa_regulations', text: 'Privacy / HIPAA Regulations' },
  { value: 'patient_advocacy_and_support', text: 'Patient Advocacy and Support' },
  { value: 'other', text: 'Other' },
];

export const DEFAULT_RESUME_TITLE = 'Certified Nursing Assistant';

export const DEFAULT_RESUME_ABOUT =
  'Reliable, service-focused nursing professional with excellent patient-care and charting skills gained through experience as a CNA. Compassionate and technically skilled in attending to patients in diverse healthcare settings.';

export const STUDENT_CERT_RECORD_TYPES = [
  { key: 'cna_license_no', name: 'CNA', downloadable: false },
  { key: 'cpr_url', name: 'CPR Certification', downloadable: true },
  { key: 'first_aid_url', name: 'First Aid Certification', downloadable: true },
  { key: 'bls_url', name: 'BLS Certification (Life Support)', downloadable: true },
  { key: 'tb_url', name: 'TB Test', downloadable: true },
  { key: 'vehicle_url', name: 'Vehicle Registration', downloadable: true },
  { key: 'covid_url', name: 'COVID-19 Vaccination', downloadable: true },
];

export const PARTNERSHIP_STATUS = {
  RECEIVED: 'request_received',
  ACCEPTED: 'request_accepted',
  SENT: 'request_sent',
  DECLINED: 'request_declined',
};

export const NOTIFICATION_ICONS = {
  emp_scl_partnership_requested: 'group_add',
  emp_scl_partnership_accepted: 'check_circle',
  testimonial_posted: 'rate_review',
  signup_request_posted: 'person_add',
  partnership_rec_fee_changed: 'attach_money',
  partnership_ref_fee_changed: 'attach_money',
  successfully_hired: 'how_to_reg',
  token_purchased: 'attach_money',
  std_profile_unlocked: 'remove_red_eye',
  org_std_profile_unlocked: 'remove_red_eye',
  status_approved: 'check_circle',
  user_commented: 'comment',
  user_replied: 'reply',
  feedback_posted: 'feedback',
  feedback_replied: 'feedback',
  post_liked: 'thumb_up',
  school_credited: 'toll',
  org_credits_deduct: 'money_off',
  org_token_deduct: 'money_off',
  app_feature_update: 'lightbulb_outline',
  new_signup_request: 'person_add',
  school_demo_request: 'account_balance',
  simulation_submission: '',
};
export const NOTIFICATION_ICONS_COLOR = {
  emp_scl_partnership_requested: 'primary',
  emp_scl_partnership_accepted: 'success',
  testimonial_posted: 'primary',
  signup_request_posted: 'primary',
  partnership_rec_fee_changed: 'primary',
  partnership_ref_fee_changed: 'primary',
  successfully_hired: 'primary',
  token_purchased: 'success',
  std_profile_unlocked: 'primary',
  org_std_profile_unlocked: 'primary',
  status_approved: 'success',
  user_commented: 'primary',
  user_replied: 'primary',
  feedback_posted: 'primary',
  feedback_replied: 'primary',
  post_liked: 'primary',
  school_credited: 'primary',
  org_credits_deduct: 'primary',
  org_token_deduct: 'primary',
  app_feature_update: 'primary',
  new_signup_request: 'primary',
  school_demo_request: 'primary',
  simulation_submission: '',
};
export const INVITE_STATUSES = {
  PENDING: 'pending',
  EXPIRED: 'expired',
  ACCEPTED: 'accepted',
};
export const INVITE_STATUS_COLORS = {
  [INVITE_STATUSES.ACCEPTED]: 'success',
  [INVITE_STATUSES.EXPIRED]: 'danger',
  [INVITE_STATUSES.PENDING]: 'black-70',
};

export const SEND_INVITE_STATUSES = {
  NOT_CREATED: 'invites_not_created',
  CREATED: 'invite_created',
};
export const TESTIMONIAL_STATUSES = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};
export const TESTIMONIAL_STATUSES_COLORS = {
  [TESTIMONIAL_STATUSES.PENDING]: 'black-70',
  [TESTIMONIAL_STATUSES.REJECTED]: 'danger',
  [TESTIMONIAL_STATUSES.APPROVED]: 'success',
  [TESTIMONIAL_STATUSES.VISIBLE]: 'success',
  [TESTIMONIAL_STATUSES.HIDDEN]: 'warning',
};
export const VERIFICATION_TESTIMONIAL_STATUS = {
  TESTIMONIAL_EXISTS: 'testimonial_exists',
  NO_TESTIMONIAL: 'no_testimonial',
};

export const PII_CONFIG_TYPE = {
  REQUIRED: 'required',
  DISABLED: 'disabled',
  OPTIONAL: 'optional',
};

export const FACILITY_TYPES = {
  adult_day_care: 'Adult day care',
  assisted_living: 'Assisted living',
  hospital: 'Hospital',
  hospice_care: 'Hospice care',
  home_care: 'Home care',
  longterm_care: 'Long-term care',
  other: 'Other',
};

export const SIGN_UP_REQUEST_STATUS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  ACTIVE: 'active',
};

export const OFFER_STATUS = {
  PENDING: 'Pending',
  REJECTED: 'Declined',
  APPROVED: 'Accepted',
};

export const SIGN_UP_REQUEST_STATUS_COLORS = {
  [SIGN_UP_REQUEST_STATUS.PENDING]: 'black-70',
  [SIGN_UP_REQUEST_STATUS.REJECTED]: 'danger',
  [SIGN_UP_REQUEST_STATUS.APPROVED]: 'success',
  [SIGN_UP_REQUEST_STATUS.ACTIVE]: 'success',
};

export const DEFAULT_FAVICON = '/favicon.ico';

export const PAYMENT_STATUSES = {
  PAYMENT_FAILURE: 'payment_failure',
};

export const ACTIVE_STD_INFO_DESC = 'Students who have updated their profile in the last 3 months.';

export const CARD_ICONS = {
  visa: 'visa',
  paypal: 'paypal',
  mastercard: 'mastercard',
  jcb: 'jcb',
  discover: 'discover',
  diners: 'diners-club',
  'apple-pay': 'apple-pay',
  amex: 'amex',
  'amazon-pay': 'amazon-pay',
};

export const TRANSACTION_STATUSES = {
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

export const TRANSACTION_STATUSES_COLORS = {
  [TRANSACTION_STATUSES.PENDING]: 'black-70',
  [TRANSACTION_STATUSES.SUCCEEDED]: 'success',
  [TRANSACTION_STATUSES.FAILED]: 'danger',
};

export const GENDERS = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
};

export const STD_UNLOCK_STATUSES = {
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
};

export const CREDITS_REQUIREMENTS = {
  UNLOCK_STUDENT: 3,
};

export const CREDIT_TRANSACTION_STATUSES = {
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

export const CREDIT_TRANS_STATUSES_COLORS = {
  [CREDIT_TRANSACTION_STATUSES.PENDING]: 'black-70',
  [CREDIT_TRANSACTION_STATUSES.SUCCEEDED]: 'success',
  [CREDIT_TRANSACTION_STATUSES.FAILED]: 'danger',
};

export const PROFILE_VISIBILITY_OPTIONS = [
  { value: true, text: 'Active' },
  { value: false, text: 'Paused' },
];

export const PII_TYPES = [
  'covid_vacc',
  'tb_test',
  'state_id',
  'social_sec_card',
  'cpr',
  'bls',
  'first_aid',
  'vehicle_reg',
];

export const PII_TYPE_OPTIONS = [
  { value: 'state_id', text: 'State Id' },
  { value: 'social_sec_card', text: 'Social Security Card' },
  { value: 'cpr', text: 'CPR License' },
  { value: 'tb_test', text: 'TB Test' },
  { value: 'covid_vacc', text: 'COVID-19 Vaccination' },
  { value: 'bls', text: 'Basic Life Support Certification' },
  { value: 'first_aid', text: 'First Aid Certification' },
  { value: 'vehicle_reg', text: 'Vehicle Registration' },
];

export const PII_STATUS = {
  INCOMPLETE: 'incomplete',
  EXPIRED: 'expired',
};

export const PII_TYPE_CONFIG_OPTIONS = [
  { value: null, text: 'Please Select', disabled: true },
  { value: 'required', text: 'Required' },
  { value: 'disabled', text: 'Disable' },
  { value: 'optional', text: 'Optional' },
];

export const SKILL_PRIVACY_OPTIONS = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const SCH_PUBLIC_PORTAL_LINKS = [
  'sch-public-home',
  'sch-public-parnerships',
  'sch-public-jobs',
  'sch-public-careers',
  'sch-public-resume',
  'sch-public-interviews',
  'sch-public-testimonials',
];

export const APPROVAL_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
};

export const COMMENT_TYPE = {
  QUESTION: 'question',
  SKILL: 'skill',
};

export const AUDIENCE_TYPE = {
  EVERYONE: 'everyone',
  PAST: 'past_students_and_instructors',
  CURRENT: 'current_students_and_instructors',
};

export const USER_TITLE_OPTIONS = [
  { value: 'director', text: 'Director' },
  { value: 'advisor', text: 'Advisor' },
  { value: 'instructor', text: 'Instructor' },
  { value: 'other', text: 'Other' },
];

export const USER_TITLES = {
  DIRECTOR: 'director',
  ADVISOR: 'advisor',
  INSTRUCTOR: 'instructor',
  OTHER: 'other',
};

export const PROGRAM_TYPE_OPTIONS = [
  { value: 'inperson', text: 'In-Person' },
  { value: 'blended', text: 'Hybrid' },
  { value: 'online', text: 'Online' },
];

export const PRIZE_STATUS = {
  PENDING: 'pending',
  DELIVERED: 'delivered',
};

export const SCH_PAYMENT_PLANS = {
  PAID_PLAN: 'Advanced Plan',
  FREE_PLAN: 'Free Plan',
  BASIC_PLAN: 'Basic Plan',
};

export const CNA_PAYMENT_PLANS = {
  BASIC: 'Basic',
  ADVANCED: 'Advanced',
};

export const PRIZE_STATUSES_COLORS = {
  [PRIZE_STATUS.PENDING]: 'black-70',
  [PRIZE_STATUS.DELIVERED]: 'success',
};

export const EXPIRATION_REQ_DOCS = ['state_id', 'green_card', 'passport', 'tb_test', 'cpr', 'bls', 'first_aid'];

export const STD_REG_MIN_SERVICE_FEE = 2.99;

export const QUES_TYPE = [
  { value: null, text: 'Please Select', disabled: true },
  { value: 'boolean', text: 'Boolean' },
  { value: 'short_text', text: 'Short Text' },
  { value: 'long_text', text: 'Long Text' },
  { value: 'single_select', text: 'Single Select' },
  { value: 'multi_select', text: 'Multi Select' },
  { value: 'file_type', text: 'File Type' },
];

export const STATIC_FIELD_TYPE = {
  REQUIRED: 'required',
  DISABLED: 'disabled',
  OPTIONAL: 'optional',
};
export const QUES_TYPES = {
  BOOLEAN: 'boolean',
  LONG_TEXT: 'long_text',
  SHORT_TEXT: 'short_text',
  SINGLE_SELECT: 'single_select',
  MULTI_SELECT: 'multi_select',
  FILE_TYPE: 'file_type',
};

export const GENDER_OPTIONS = [
  { value: 'male', text: 'Male' },
  { value: 'female', text: 'Female' },
  { value: 'other', text: 'Other' },
];

export const ETHNICITY_OPTIONS = [
  { value: 'hispanic', text: 'Hispanic' },
  { value: 'black_non_hispanic_black', text: 'Black / Non-Hispanic Black' },
  { value: 'white_non_hispanic', text: 'White / Non-Hispanic' },
  { value: 'american_indian_alaskan_native', text: 'American Indian / Alaskan Native' },
  { value: 'asian', text: 'Asian' },
  { value: 'middle_eastern', text: 'Middle Eastern' },
  { value: 'hawaiian_native_pacific_islander', text: 'Hawaiian Native / Pacific Islander' },
  { value: 'multiracial', text: 'Multiracial' },

  { value: 'other', text: 'Other' },
  { value: 'choose_not_to_report', text: 'Choose Not To Report' },
];

export const SPONSORSHIP_SUBMISSION_TYPES = {
  IN_APP_SUBMISSION: 'in_app_submission',
  EXTERNAL_SUBMISSION: 'external_submission',
};

export const YES_NO_OPTIONS = [
  { value: true, text: 'Yes' },
  { value: false, text: 'No' },
];

export const SPONSORSHIP_CATEGORIES = [
  // { value: 'employment', text: 'Employment' },
  // { value: 'grant', text: 'Grant' },
  // { value: 'loan', text: 'Loan' },
  { value: 'direct_sponsorship', text: 'Direct Sponsorship' },
  { value: 'apprenticeships', text: 'Apprenticeships' },
  { value: 'scholarships', text: 'Scholarships' },
  { value: 'tuition_reimbursement', text: 'Tuition Reimbursement' },
  { value: 'fellowships', text: 'Fellowships' },
];

export const SPONSORSHIP_CATEGORIES_TYPES = {
  EMPLOYMENT: 'apprenticeships',
  // GRANT: 'grant',
  // LOAN: 'loan',
  DIRECT_SPONSORSHIP: 'direct_sponsorship',
  // APPRENTICESHIPS: 'apprenticeships',
  SCHOLARSHIPS: 'scholarships',
  TUITION_REIMBURSEMENT: 'tuition_reimbursement',
  FELLOWSHIPS: 'fellowships',
};

export const STUDENT_PAYMENT_TYPES = {
  PAID: 'paid',
  CANCELLED: 'cancelled',
  UNPAID: 'unpaid',
};

export const REVIEW_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const SCHOOL_OFFERINGS = [
  { value: 'online_classes', text: 'Online Classes' },
  { value: 'payment_plans', text: 'Payment Plans' },
  { value: 'online_enrollment', text: 'Online Enrollment' },
  { value: 'WIOA', text: 'WIOA' },
  { value: 'job_placement', text: 'Job Placement' },
  { value: 'graduation_ceremony', text: 'Graduation Ceremony' },
  { value: 'military_discount', text: 'Military Discount' },
  { value: 'hybrid_classes', text: 'Hybrid Classes' },
  { value: 'employer_partnerships', text: 'Employer partnerships' },
  { value: 'continuing_education_units_ceus', text: 'Continuing Education Units (CEUs)' },
  { value: 'other', text: 'Other' },
];
export const UNI_OFFERINGS = [
  {
    label: 'University Size',
    options: [
      { value: 'small', text: 'Small School' },
      { value: 'medium', text: 'Medium School' },
      { value: 'large', text: 'Large School' },
    ],
  },

  {
    label: 'Location',
    options: [
      { value: 'urban_community', text: 'Urban Community' },
      { value: 'suburban', text: 'Suburban Community' },
      { value: 'rural', text: 'Rural Community' },
    ],
  },
  {
    label: 'Affiliation',
    options: [
      { value: 'public_uni', text: 'Public University' },
      { value: 'private_uni', text: 'Private University' },
      { value: 'religious_inst', text: 'Religious Institution' },
    ],
  },

  {
    label: 'Specialty Tags',
    options: [
      { value: 'hbcu', text: 'HBCU' },
      { value: 'hsis', text: 'HSIs' },
      { value: 'all_womens_colleges', text: 'All-Women’s Colleges' },
      { value: 'liberal_arts_focus', text: 'Liberal Arts Focus' },
      { value: 'research_intensive_institutions', text: 'Research-Intensive Institutions' },
      { value: 'strong_clinical_partnership', text: 'Strong Clinical Partnerships' },
      { value: 'robust_pre_health_advising', text: 'Robust Pre-Health Advising' },
      { value: 'research_oppor', text: 'Research Opportunities' },
      { value: 'high_medical_scl_accpt', text: 'High Medical School Acceptance Rates' },
      { value: 'schl_pre_healthcare_scl', text: 'Scholarships for Pre-Healthcare Students' },
    ],
  },
];

export const SCL_AUDIENCE_OPT = [
  { value: null, text: 'Please select audience', disabled: true },
  { value: 'everyone', text: 'Everyone' },
  { value: 'past_students_and_instructors', text: 'Alumni & instructors' },
  { value: 'current_students_and_instructors', text: 'Current students & instructors' },
];
export const AUDIENCE_OPTIONS = [
  { value: null, text: 'Please select audience', disabled: true },
  { value: 'everyone', text: 'Everyone' },
  { value: 'past_students_and_instructors', text: 'Alumni & instructors' },
  { value: 'current_students_and_instructors', text: 'Current students & instructors' },
  { value: 'student_from_my_program_and_instructors', text: 'Current students in my Program & Instructors' },
];
export const PAST_STD_AUDIENCE_OPTION = [
  { value: null, text: 'Please select audience', disabled: true },
  { value: 'past_students_and_instructors', text: 'Alumni & instructors' },
];

export const CURRENT_STD_AUDIENCE_OPTION = [
  { value: null, text: 'Please select audience', disabled: true },
  { value: 'current_students_and_instructors', text: 'Current students & instructors' },
  { value: 'student_from_my_program_and_instructors', text: 'Current students in my Program & Instructors' },
];

export const EMAIL_SUBSCRIPTION_STATUS = {
  DISABLED: 'disabled',
  ENABLED: 'enabled',
  INVALID_TOKEN: 'invalid_token',
};

export const SIMULATION_VOICES_OPTIONS = [
  {
    value: 'openai-Nova',
    name: 'Nova',
    text: 'Female',
    image: 'nova.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nova_.wav',
  },
  {
    value: 'openai-Alloy',
    name: 'Alloy',
    text: 'Male',
    image: 'alloy.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/alloy_.wav',
  },
  {
    value: 'openai-Echo',
    name: 'Echo',
    text: 'Male',
    image: 'echo.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/echo_.wav',
  },
  {
    value: 'openai-Fable',
    name: 'Fable',
    text: 'Male',
    image: 'fable.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/fable_.wav',
  },
  {
    value: 'openai-Onyx',
    name: 'Onyx',
    text: 'Male',
    image: 'onyx.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/onyx_.wav',
  },
  {
    value: 'openai-Shimmer',
    name: 'Shimmer',
    text: 'Female',
    image: 'shimmer.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/shimmer_.wav',
  },
];

export const SCHOOL_DEF_CANCEL_POLICY = `<p><strong>Cancellation and Refund Policy</strong><br />Before proceeding with enrollment, please read and understand our Terms and Conditions.<br /><br /><strong>1. Acceptance of Terms</strong><br />By enrolling in any of our training programs, you agree to comply with and be legally bound by these terms and conditions.<br /><br /><strong>2. Non-refundable Fees</strong><br />The tuition paid for enrollment includes processing fees, which are collected by our payment provider, Stripe. Please note that these processing fees, as well as the initial deposit, are non-refundable, regardless of withdrawal status. This is due to the non-retrievable nature of these fees once paid to the payment provider.<br /><br /><strong>3. Program Withdrawal</strong><br />If you choose to withdraw from a program for reasons unrelated to our school, please understand that the processing fees and application fee will NOT be refunded. Attempting to dispute these charges with your bank will not result in a refund, as you have been clearly informed that these fees are non-refundable. Ensure you are fully committed to attending this training course before completing your application. If you’re unable to attend a specific class date after enrolling, please contact the school administration directly to transfer to another class start date without incurring an additional application fee.<br /><br />
<strong>4. Confirmation of Enrollment</strong><br />By signing this agreement, you confirm that you are the rightful consumer purchasing this course with the intention to attend and complete it.<br /><br /><strong>5. Questions or Concerns</strong><br />If you are unsure or have questions about this agreement, please contact the school administration before proceeding with your enrollment.<br /><br />By proceeding with enrollment, you acknowledge that you understand and agree to these terms. </p>`;

export const constants = {};

constants.youtubeLinkRegex =
  /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;