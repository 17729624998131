import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getRegistrationForm(_obj, { params }) {
    const res = await axios.get(`api/registration-form/`, { params });
    return res.data;
  },

  async CreateApplicationForm(_obj, data) {
    return axios.post(`api/application-forms/scholarship-application/`, data);
  },

  async updateRegistrationForm(_obj, { data }) {
    return axios.patch(`api/application-forms/custom-question/update-static-fields/`, data);
  },
  async getPublicRegistrationForm(_obj, { params }) {
    const res = await axios.get(`api/application-forms/custom-question/public-static-fields/`, { params });
    return res.data;
  },

  async createCustomQuestion(_obj, { data }) {
    return axios.post(`api/application-forms/custom-question/`, data);
  },

  async updateCustomQuestion(_obj, { id, data }) {
    return axios.patch(`api/application-forms/custom-question/${id}/`, data);
  },

  async getCustomQuestions(_obj, params) {
    const res = await axios.get(`api/application-forms/custom-question/`, { params });
    return res.data;
  },

  async getQuestion(_obj, id) {
    const res = await axios.get(`api/application-forms/custom-question/${id}/`);
    return res.data.data;
  },

  async deleteCustomQuestion(_obj, id) {
    return axios.delete(`api/application-forms/custom-question/${id}/`);
  },
  async getAllSchApps(_obj, params) {
    const res = await axios.get(`api/application-forms/scholarship-application/`, { params });
    return res.data;
  },
  async getApplicationProfile(_obj, params) {
    const res = await axios.get(`api/application-forms/scholarship-application/applicant-profile/`, { params });
    return res.data;
  },
  async getEmailStatus(_obj, data) {
    return axios.post(`api/application-forms/scholarship-application/student-exists/`, data);
  },
  async createManualApplication(_obj, data) {
    return axios.post(`api/application-forms/scholarship-application/`, data);
  },

  async getApplicationResponse(_obj, params) {
    const res = await axios.get(`api/application-forms/scholarship-application/applicant-profile/`, { params });
    return res.data;
  }
};

export const applicationForm = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
