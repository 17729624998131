<template>
  <fixed-layout
    :container-class="containerClass"
    :drawer-align="mainDrawerAlign"
    :sidebar-type="sidebarType"
    :sidebar-variant="sidebarVariant"
    :sidebar-brand="$store.state.brand"
    :sidebar-search="sidebarSearch"
    sidebar-search-form-class="search-form--black"
    :sidebar-brand-class="sidebarBrandClass"
    :sidebar-menu="$store.getters['sidebarMenuConfig/layoutMenu']"
    :header-class="headerClass"
    :header-effects="headerEffects"
    :header-image="headerImage"
    :header-content-class="headerContentClass"
    :header-condenses="headerCondenses"
    :header-content-component="headerContentComponent"
    :header-content-component-props="headerContentComponentProps"
    :sub-layout="subLayout"
    :sub-layout-has-scrolling-region="subLayoutHasScrollingRegion"
    :sub-layout-drawer="subLayoutDrawer"
    :sub-layout-drawer-id="subLayoutDrawerId"
    :sub-layout-drawer-align="subLayoutDrawerAlign"
    :navbar-type="navbarType"
    :navbar-variant="navbarVariant"
    :navbar-container-class="navbarContainerClass"
    :navbar-brand="$store.state.brand"
    :navbar-full-sample-guest-mode="!isLoggedIn"
    :footer-brand="$store.state.brand"
    :footer-class="`bg-dark border-top-2 mt-4`"
  >
    <!-- Replace Navbar Component -->
    <template v-slot:navbar>
      <app-navbar
        data-primary
        :navbar-type="navbarType"
        :navbar-variant="navbarVariant"
        :navbar-brand="$store.state.brand"
        :navbar-container-class="navbarContainerClass"
        :navbar-full-sample-guest-mode="!isLoggedIn"
        navbar-toggle-class="d-lg-none"
        class="p-0"
      >
        <slot name="navbar-content" />
      </app-navbar>
    </template>

    <!-- Replace Navbar Content -->
    <!-- <template v-slot:navbar-content>
      Replace Navbar Content
    </template> -->

    <!-- Replace Sidebar Component -->
    <template v-slot:sidebar>
      <!-- Use $store.state.brand if branding required -->
      <app-sidebar
        :sidebar-search="sidebarSearch"
        sidebar-search-form-class="search-form--black"
        :sidebar-brand="false"
        :sidebar-brand-class="sidebarBrandClass"
        :sidebar-type="sidebarType"
        :sidebar-variant="sidebarVariant"
        :sidebar-menu="$store.getters['sidebarMenuConfig/layoutMenu']"
      >
        <template v-slot:default="{ sidebar }">
          <slot name="sidebar-content" v-bind:sidebar="sidebar" />
        </template>
      </app-sidebar>
    </template>

    <!-- Replace Sidebar Content -->
    <!-- <template v-slot:sidebar-content="{sidebar}">
      <div class="sidebar-heading">Replace Sidebar Content</div>
      <pre
        class="sidebar-text"
        v-text="sidebar.menu" />
    </template> -->

    <!-- Page Content -->
    <template v-slot:default="{ layoutProps }">
      <b-skeleton-wrapper
        :loading="getIsProfLoad"
        class="mb-3"
        v-if="
          get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.STUDENT &&
          !['student-onboarding', 'accept-invite'].includes($route.name)
        "
      >
        <template #loading>
          <!-- <div class="px-5 pt-2">
            <b-skeleton class="w-100"></b-skeleton>
          </div> -->
        </template>
        <div class="px-4 pt-2" v-if="getStatus === PII_STATUS.INCOMPLETE">
          <div class="alert alert-primary w-100" role="alert">
            <span class="text-90">You haven't uploaded all of your required records and credentials.</span>
            <router-link
              :to="{ name: 'std-manage-records', query: { scrollTo: 'piiCard' } }"
              style="text-decoration: underline"
              v-if="$route.name !== 'std-manage-records'"
            >
              Click here to upload documents.
            </router-link>
          </div>
        </div>
        <div class="px-5 pt-2" v-if="getStatus === PII_STATUS.EXPIRED">
          <div class="alert alert-danger w-100" role="alert">
            <span class="text-90">Some of your records/credentials have been expired.</span>
            <router-link
              :to="{ name: 'std-manage-records', query: { scrollTo: 'piiCard' } }"
              style="text-decoration: underline"
              v-if="$route.name !== 'std-manage-records'"
            >
              Click here to update your documents.
            </router-link>
          </div>
        </div>
      </b-skeleton-wrapper>
      <router-view v-bind:layout-props="layoutProps" />
    </template>

    <!-- Footer Content -->
    <template v-slot:footer-content="footerProps">
      <!-- <p class="text-70 brand mb-24pt">
        <strong class="text-50 small mr-2">POWERED BY</strong>
        <img class="brand-icon" src="@/assets/images/logos/logo_icon.png" width="20" :alt="footerProps.footerBrand" />
        {{ footerProps.footerBrand }}
      </p>

      <p class="mb-8pt d-flex">
        <a class="text-70 text-underline mr-8pt small">Terms</a>
        <a class="text-70 text-underline small">Privacy</a>
      </p>

      <p class="text-50 measure-lead-max small">
        Copyright {{ footerProps.copyrightYear }} &copy; All rights reserved!
      </p> -->
      <div class="text-70 public-footer" :class="isMobSmallScreen ? 'mb-5' : ''">
        <div :class="isMobSmallScreen ? 'text-center' : 'd-flex align-items-center justify-content-center'">
          <div :class="isMobSmallScreen ? 'mb-3' : 'mr-5'" v-if="get(getCurrentSchool, 'phone')">
            <md-icon class="mb-1 icon mr-1">local_phone</md-icon>
            <a :href="`tel:${get(getCurrentSchool, 'phone')}`">{{ get(getCurrentSchool, 'phone') }} </a>
          </div>
          <div :class="isMobSmallScreen ? 'mb-3' : 'mr-5'" v-if="get(getCurrentSchool, 'contact_email')">
            <md-icon class="mb-1 icon mr-1">email</md-icon>
            <a :href="`mailto:${get(getCurrentSchool, 'contact_email')}`">Email</a>
          </div>
          <div v-if="get(getCurrentSchool, 'website_url')">
            <md-icon class="mb-1 icon mr-1">language</md-icon>
            <a target="_blank" :href="get(getCurrentSchool, 'website_url')">Our Website</a>
          </div>
        </div>
        <div class="mt-4 text-center">
          <a
            :href="`http://maps.google.com/?q=1200${get(getCurrentSchool, 'name')}, ${get(
              getCurrentSchool,
              'address'
            )}, ${get(getCurrentSchool, 'city')}, ${get(getCurrentSchool, 'state')}`"
            target="_blank"
          >
            {{ get(getCurrentSchool, 'name') }}
          </a>
        </div>
        <div
          v-if="
            get(getCurrentSchool, 'address') &&
            get(getCurrentSchool, 'city') &&
            get(getCurrentSchool, 'state') &&
            get(getCurrentSchool, 'zipcode') &&
            get(getCurrentSchool, 'country')
          "
          class="mt-2 text-center"
        >
          <md-icon class="mb-1 icon">location_on</md-icon>
          <a
            :href="`http://maps.google.com/?q=1200${get(getCurrentSchool, 'name')}, ${get(
              getCurrentSchool,
              'address'
            )}, ${get(getCurrentSchool, 'city')}, ${get(getCurrentSchool, 'state')}`"
            target="_blank"
          >
            {{ get(getCurrentSchool, 'address') }}, {{ get(getCurrentSchool, 'city') }},
            {{ get(getCurrentSchool, 'state') }} {{ get(getCurrentSchool, 'zipcode') }},
            {{ get(getCurrentSchool, 'country') }}
          </a>
        </div>
        <div class="mt-5 text-center">
          <strong class="small mr-2">POWERED BY</strong>
          <img
            class="brand-icon"
            src="@/assets/images/logos/logo_white.png"
            width="100"
            :alt="footerProps.footerBrand"
          />
        </div>
      </div>
    </template>
  </fixed-layout>
</template>

<script>
import FixedLayout from '@/components/Layout/FixedLayout.vue';
// import AppSettings from '@/components/Settings';
import { mapGetters } from 'vuex';
import mainDrawerMixin from '@/mixins/main-drawer';
import sidebarConfigMixin from '@/mixins/sidebar-config';
import navbarConfigMixin from '@/mixins/navbar-config';
import layoutConfigMixin from '@/mixins/layout-config';
import subLayoutMixin from '@/mixins/sub-layout';
import { USER_ROLE_TYPES, PII_STATUS } from '@/common/constants';
import AppNavbar from '@/components/Navbar/Navbar.vue';
import AppSidebar from '@/components/Sidebar/Sidebar.vue';
import { get } from 'lodash';

export default {
  components: {
    FixedLayout,
    AppNavbar,
    AppSidebar,
    // AppSettings,
  },
  mixins: [mainDrawerMixin, sidebarConfigMixin, navbarConfigMixin, layoutConfigMixin, subLayoutMixin],
  data() {
    return {
      headerClass: null,
      headerEffects: 'waterfall',
      headerImage: null,
      headerContentClass: null,
      headerCondenses: false,
      headerContentComponent: null,
      headerContentComponentProps: null,
      windowWidth: window.innerWidth,
      USER_ROLE_TYPES,
      PII_STATUS,
    };
  },
  methods: {
    get,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('student', ['getStudentProfile', 'getIsProfLoad', 'getStatus']),
    ...mapGetters('auth', ['getLoggedInUser']),
    sidebarSearch() {
      return false;
    },
    isMobSmallScreen() {
      return this.windowWidth <= 576;
    },
    sidebarBrandClass() {
      return null;
    },
    sidebarConfigKey() {
      return this.mainDrawerSidebar;
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  created() {
    this.$root.$on('luma::headerClass', (headerClass) => (this.headerClass = headerClass));
    this.$root.$on('luma::headerEffects', (headerEffects) => (this.headerEffects = headerEffects));
    this.$root.$on('luma::headerImage', (headerImage) => (this.headerImage = headerImage));
    this.$root.$on('luma::headerContentClass', (headerContentClass) => (this.headerContentClass = headerContentClass));
    this.$root.$on('luma::headerCondenses', (headerCondenses) => (this.headerCondenses = headerCondenses));
    this.$root.$on(
      'luma::headerContentComponent',
      (headerContentComponent) => (this.headerContentComponent = headerContentComponent)
    );
    this.$root.$on(
      'luma::headerContentComponentProps',
      (headerContentComponentProps) => (this.headerContentComponentProps = headerContentComponentProps)
    );
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
