import Vue from 'vue';
import App from './App.vue';

import { createRouter } from './router';
import store from './store';
import * as Sentry from '@sentry/vue';

import i18n from './i18n';
import http from './http';

import './plugins/vue-meta';
import './plugins/bootstrap-vue';

import './plugins/fmv-vendor';
import './plugins/fmv-layout';

import './plugins/app';
import './plugins/v-mask';
import './plugins/vue-select';
import './plugins/vue-slider-component';
import './plugins/vue-form-wizard';
import './plugins/vuelidate';
import './plugins/currency-input';
import './plugins/vue-safe-html';
import './plugins/multiselect';
import '@/assets/scss/vendor/material-icons.scss';
import '@/assets/scss/vendor/fontawesome.scss';
import '@/assets/scss/app.scss';
import '@/assets/scss/vendor/quill.scss';

Vue.config.productionTip = false;

// export const createApp = async ({
//   beforeApp = () => {},
//   afterApp = () => {}
// } = {}) => {
//   const router = createRouter()

//   await beforeApp({
//     router,
//     store
//   })

//   const app = new Vue({
//     router,
//     store,
//     i18n,
//     http,
//     render: h => h(App),
//     mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
//   })

//   const result = {
//     app,
//     router,
//     store
//   }

//   await afterApp(result)

//   return result
// }

const router = createRouter();

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          'localhost',
          process.env.VUE_APP_MAIN_DOMAIN,
          /^http[s]?:\/\/.+\.jointransition.com/i,
          /^http:\/\/.+\.localhost/i,
          /^\//
        ]
      })
    ],
    tracesSampleRate: 0.25
  });
}

new Vue({
  router,
  store,
  i18n,
  http,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app');
