<template>
  <b-nav-item-dropdown variant="flush" no-caret right class="dropdown-notifications dropdown-xs-down-full d-sm-flex">
    <template slot="button-content">
      <md-icon :class="iconClass">{{ icon }}</md-icon>
      <b-badge v-if="badge && myPoints" pill variant="primary badge-points">{{ $n(myPoints) }}</b-badge>
    </template>

    <div>
      <!-- <div class="row mt-3 ml-4 mr-3 d-flex justify-content-between">
        <h6>Wallet</h6>
        <router-link :to="{ name: 'manage-credits-history' }">
          <md-icon :class="iconClass" v-b-popover.hover.top="'Refresh'">history</md-icon>
        </router-link>
      </div> -->

      <div class="my-2">
        <h6 class="text-center">Points Earned</h6>
        <h4 class="text-center d-flex justify-content-center align-items-center">
          <span class="mr-1">{{ $n(myPoints) }}</span> <md-icon>stars</md-icon>
        </h4>
      </div>
    </div>
  </b-nav-item-dropdown>
</template>

<i18n>
  {
    "en": {
      "view_all": "View {title}"
    },
    "ro": {
      "view_all": "Vezi {title}",
      "Messages": "Mesaje"
    }
  }
</i18n>

<script>
import routeToMixin from '@/mixins/route-to';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLES, USER_ROLE_TYPES } from '@/common/constants';
export default {
  name: 'NavStdWallet',
  components: {},
  mixins: [routeToMixin],
  props: {
    badge: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'account_balance_wallet',
    },
    iconClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default() {
        return 'Wallet';
      },
    },
    routeMessages: {
      type: [String, Object],
    },
  },
  data() {
    return {
      isLoading: false,
      USER_ROLES,
      USER_ROLE_TYPES,
      myPoints: 0,
    };
  },
  methods: {
    ...mapActions('student', ['getMypoints']),

    async fetchPoints() {
      this.isLoading = true;
      const resp = await this.getMypoints();
      this.myPoints = resp.data.points;

      this.isLoading = false;
    },
  },

  mounted() {
    this.fetchPoints();
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },
};
</script>

<style lang="scss" scoped>
.badge-points {
  position: absolute;
  top: 4.5px;
  right: 5px;
  color: white;
  border-radius: 50%;
  font-size: 11.5px;
  font-weight: bold;
  padding: 4px 6px;
  min-width: 24px;
  text-align: center;
  line-height: 1;
  border: 2px solid white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
