import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async createComment(_obj, data) {
    const res = await axios.post(`api/comments/`, data);
    return res.data;
  },
  async getComments(_obj, params) {
    const res = await axios.get(`api/comments/`, { params });
    return res.data;
  },
  async getCommentCount(_obj, data) {
    const res = await axios.post(`api/comments/comments-count/`, data);
    return res.data;
  }
};

export const comments = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
