import axios from 'axios';

const state = {};

const getters = {};

const actions = {
  async getAllNotifications(_obj, params) {
    const res = await axios.get(`api/notifications/`, { params });
    return res.data;
  },

  async updateNotification(_obj, { id, data }) {
    return axios.patch(`api/notifications/${id}/`, data);
  },

  async markAllRead() {
    const res = await axios.put('api/notifications/mark-all-read/');
    return res.data;
  }
};

const mutations = {};

export const notifications = { namespaced: true, state, getters, actions, mutations };
