import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async verifyPaymentInfo(_obj, params) {
    const res = await axios.get(`api/payments/payment-methods/verify-payment-info/`, { params });
    return res.data;
  },

  async getPayments(_obj, params) {
    const res = await axios.get(`api/payments/`, { params });
    return res.data;
  },

  async getStdPayments(_obj, params) {
    const res = await axios.get(`api/payments/payout-from-healthenroll/`, { params });
    return res.data;
  },

  async savePaymentMethod(_obj, data) {
    return axios.post(`api/payments/payment-methods/`, data);
  },

  async savePaymentMethodSuccess(_obj, data) {
    return axios.post(`api/payments/payment-methods/save-success/`, data);
  },

  async getDefaultPaymentMethod(_obj, data) {
    return axios.get(`api/payments/payment-methods/`, data);
  },

  async removeDefaultPaymentMethod() {
    return axios.delete(`api/payments/payment-methods/remove-def-method/`);
  },

  async getCustomerPortalLink(_obj, params) {
    const res = await axios.get(`api/payments/customer-portal-healthenroll/`, { params });
    return res.data;
  },

  async getHEPaymentPlans() {
    const res = await axios.get(`api/payments/payment-detail-healthenroll/`);
    return res.data;
  },

  async getSingleHEPaymentPlan(_obj, params) {
    const res = await axios.get(`api/payments/payment-retrieve-healthenroll/`, { params });
    return res.data;
  },

  async createPaymnetPlanIntent(_obj, data) {
    const res = await axios.post(`api/payments/payment-intent-healthenroll/`, data);
    return res.data;
  },

  async paymentPlanSuccess(_obj, data) {
    const res = await axios.post(`api/payments/payment-success-healthenroll/`, data);
    return res.data;
  }
};

export const payments = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
