import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getLikes(_obj, params) {
    const res = await axios.get(`api/likes/`, { params });
    return res.data;
  },
  async createLike(_obj, data) {
    const res = await axios.post(`api/likes/`, data);
    return res.data;
  },
  async getLikesCount(_obj, data) {
    const res = await axios.post(`api/likes/like-count/`, data);
    return res.data;
  },
  async unLike(_obj, data) {
    const res = await axios.post(`api/likes/unlike-post/`, data);
    return res.data;
  }
};

export const likes = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
