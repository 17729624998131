import store from '../store';
import { SCH_PAYMENT_PLANS } from '../common/constants';
export async function basicPayment(to, from, next) {
  if (
    ['student-attendance', 'mark-attendance', 'edit-attendance', 'attendance-summary', 'view-attendance'].includes(
      to.name
    )
  ) {
    if (store.getters['auth/getLoggedInUser'].linked_entity.sch_payment_plan.title !== SCH_PAYMENT_PLANS.FREE_PLAN) {
      next();
    } else next({ name: 'student-home' });
  }
}
