import store from '../store';
import { get, includes, isEmpty } from 'lodash';
import { USER_ROLES } from '../common/constants';
import { homeGuard } from './homeGuard';
import { studentOnboardGuard } from './studentOnboardGuard';

// Role-based authentication route guard.
export async function authenticate(to, from, next) {
  if (to.name === 'login') {
    if (store.getters['auth/isLoggedIn']) {
      // Select appropriate layout.
      if (store.getters['auth/getLoggedInUser'].role === USER_ROLES.STUDENT) {
        store.dispatch('settings/setSettings', { layout: { layout: 'fixed' } });
      } else {
        store.dispatch('settings/setSettings', { layout: { layout: 'sticky' } });
      }

      // Redirect to relevant user's home
      homeGuard(to, from, next);
    } else {
      next();
    }
    // } else if (to.meta.auth) {
  } else if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters['auth/isLoggedIn']) {
      if (to.name === 'home') {
        // Redirect unauth school to school's public homepage.
        let schoolResp;
        try {
          schoolResp = await store.dispatch('school/getSchoolFromCurrentDomain', {});
        } catch (_err) {
          // Handle error if needed.
        }
        if (!isEmpty(schoolResp)) {
          next({ name: 'sch-public-home' });
        } else {
          next({ name: 'login' });
        }
      } else {
        next({ name: 'login', query: { redirect: to.path } });
      }
    } else {
      if (
        !get(to.meta, 'rolesRequired.length') ||
        includes(to.meta.rolesRequired, store.getters['auth/getLoggedInUser'].role)
      ) {
        // Select appropriate layout.
        if (store.getters['auth/getLoggedInUser'].role === USER_ROLES.STUDENT) {
          store.dispatch('settings/setSettings', { layout: { layout: 'fixed' } });
          studentOnboardGuard(to, from, next);
        } else {
          store.dispatch('settings/setSettings', { layout: { layout: 'sticky' } });
          next();
        }
      } else {
        homeGuard(to, from, next);
      }
    }
  } else if (to.matched.some(record => record.meta.onlyUnauth)) {
    if (store.getters['auth/isLoggedIn']) {
      next({ name: 'login' });
    } else if (to.meta.onlyUnauthSchool) {
      let schoolResp;
      try {
        schoolResp = await store.dispatch('school/getSchoolFromCurrentDomain', {});
      } catch (_err) {
        // Handle error if needed.
      }
      if (isEmpty(schoolResp)) {
        next({ name: 'login' });
      } else {
        next();
      }
    } else if (to.meta.onlyMainDomain && window.location.host !== process.env.VUE_APP_MAIN_DOMAIN) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
}
