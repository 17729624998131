import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllQuestions(_obj, params) {
    const res = await axios.get(`api/question-answers/`, { params });
    return res.data;
  },
  async getStudentQuestions(_obj, params) {
    const res = await axios.get(`api/question-answers/student-questions/`, { params });
    return res.data;
  },
  async getQuestion(_obj, id) {
    const res = await axios.get(`api/question-answers/${id}/`);
    return res.data;
  },

  async createQuestion(_obj, data) {
    return axios.post(`api/question-answers/`, data);
  },

  async updateQuestion(_obj, { id, data }) {
    return axios.patch(`api/question-answers/${id}/`, data);
  },

  async deleteQuestion(_obj, id) {
    return axios.delete(`api/question-answers/${id}/`);
  }
};

export const questions = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
