import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import { routes } from './routes';
import { authenticate } from '../guards/authGuard';

import scrollBehavior from './scrollBehavior';

export const createRouter = () => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior
  });

  router.beforeEach((to, from, next) => {
    authenticate(to, from, next);
  });

  return router;
};

const router = createRouter();
export default router;
