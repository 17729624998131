import axios from 'axios';
import { get } from 'lodash';
import { USER_ROLES } from '@/common/constants';
const state = {
  availableTokens: 0,
  availableJobCredits: 0,
  availableCredits: 0
};

const getters = {
  getAvailableTokens: state => state.availableTokens,
  getAvailableJobCredits: state => state.availableJobCredits,
  getAvailableCredits: state => state.availableCredits
};

const mutations = {
  updateAvailableTokens: (state, updatedTokens) => {
    state.availableTokens = updatedTokens;
  },
  updateAvailableJobCredits: (state, updateCredits) => {
    state.availableJobCredits = updateCredits;
  },
  updateAvailableCredits: (state, updateCredits) => {
    state.availableCredits = updateCredits;
  }
};

const actions = {
  async getPrizeImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/credits/prize/image-upload-url/`, data)).data.data;
  },
  async getPrize(_obj, id) {
    const res = await axios.get(`api/credits/prize/${id}/`);
    return res.data;
  },
  async updatePrizeStatus(_obj, { id, data }) {
    return axios.patch(`api/credits/prize/${id}/update-is-active/`, data);
  },
  async updateRedeemPrizeStatus(_obj, { id, data }) {
    return axios.patch(`api/credits/credits-redemption/${id}/update-delivery-status/`, data);
  },
  async createPrize(_obj, data) {
    return axios.post(`api/credits/prize/`, data);
  },
  async getSchoolPrizes(_obj, params) {
    const res = await axios.get(`api/credits/credits-redemption/school-prizes/`, { params });
    return res.data;
  },
  async getAllPrizes(_obj, params) {
    const res = await axios.get(`api/credits/prize/prize-schools-list/`, { params });
    return res.data;
  },
  async getAllRedeemPrizes(_obj, params) {
    const res = await axios.get(`api/credits/credits-redemption/redeemed-prizes/`, { params });
    return res.data;
  },
  async updatePrize(_obj, { id, data }) {
    return axios.patch(`api/credits/prize/${id}/`, data);
  },
  async deletePrize(_obj, id) {
    return axios.delete(`api/credits/prize/${id}/`);
  },

  async getEmployerAvailableTokens(_obj, params) {
    const res = await axios.get(`api/employers/available-tokens/`, { params });
    return res.data.data;
  },
  async getOrgAvailableTokens(_obj, params) {
    const res = await axios.get(`api/organizations/available-tokens/`, { params });
    return res.data.data;
  },
  async getSchoolAvailableCredits(_obj, params) {
    const res = await axios.get(`api/credits/prize/school-credits/`, { params });
    return res.data.data;
  },

  async updateStateCredits({ dispatch, commit }) {
    await commit('updateAvailableCredits', 0);
    const resp = await dispatch('getSchoolAvailableCredits');
    commit('updateAvailableCredits', get(resp, 'available_credits', 0));
  },
  async updateStateAvailTokens({ rootGetters, dispatch, commit }) {
    await commit('updateAvailableTokens', 0);
    let resp;
    if (rootGetters['auth/getLoggedInUser'].role === USER_ROLES.ORGANIZATION) {
      resp = await dispatch('getOrgAvailableTokens');
    } else {
      resp = await dispatch('getEmployerAvailableTokens');
    }
    commit('updateAvailableTokens', get(resp, 'available_tokens', 0));
    commit('updateAvailableJobCredits', get(resp, 'available_job_credits', 0));
  },

  async getCreditTransactions(_obj, params) {
    const res = await axios.get(`api/credits/transaction/`, { params });
    return res.data;
  },

  async purchaseTokens(_obj, data) {
    const res = await axios.post(`api/credits/purchase-tokens/`, data);
    return res.data;
  },
  async redeemCredits(_obj, { id, data }) {
    const res = await axios.get(`api/credits/prize/${id}/redeem-tokens-with-prize/`, data);
    return res.data;
  },

  async purchaseTokensPaymentSuccess(_obj, data) {
    const res = await axios.post(`api/credits/purchase-tokens-pay-success/`, data);
    return res.data;
  },

  async getAllPackages(_obj, params) {
    const res = await axios.get(`api/credits/`, { params });
    return res.data;
  },

  async getAllSystemConfigs(_obj, params) {
    const res = await axios.get(`api/credits/system-config/`, { params });
    return res.data;
  },

  async updateSystemConfig(_obj, { id, data }) {
    return axios.patch(`api/credits/system-config/${id}/`, data);
  }
};

export const credit = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
