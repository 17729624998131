import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async createFeedback(_obj, data) {
    const res = await axios.post(`api/feedbacks/`, data);
    return res.data;
  },
  async getFeedback(_obj, params) {
    const res = await axios.get(`api/feedbacks/`, { params });
    return res.data;
  },
  async getFeedbackCount(_obj, data) {
    const res = await axios.post(`api/feedbacks/feedback-count/`, data);
    return res.data;
  }
};

export const feedback = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
