var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sticky-layout',{attrs:{"body-class":_vm.bodyClass,"container-class":_vm.containerClass,"drawer-align":_vm.mainDrawerAlign,"sidebar-type":_vm.sidebarType,"sidebar-variant":_vm.sidebarVariant,"sidebar-search-form-class":"search-form--black","sidebar-menu":_vm.$store.getters['sidebarMenuConfig/layoutMenu'],"header-class":_vm.headerClass,"sub-layout":_vm.subLayout,"sub-layout-has-scrolling-region":_vm.subLayoutHasScrollingRegion,"sub-layout-drawer":_vm.subLayoutDrawer,"sub-layout-drawer-id":_vm.subLayoutDrawerId,"sub-layout-drawer-align":_vm.subLayoutDrawerAlign,"navbar-type":_vm.navbarType,"navbar-variant":_vm.navbarVariant,"navbar-container-class":_vm.navbarContainerClass,"navbar-brand":_vm.$store.state.brand,"navbar-full-sample-guest-mode":!_vm.isLoggedIn,"footer-brand":_vm.$store.state.brand},scopedSlots:_vm._u([{key:"navbar",fn:function(){return [_c('app-navbar',{attrs:{"data-primary":"","navbar-type":_vm.navbarType,"navbar-variant":_vm.navbarVariant,"navbar-brand":_vm.$store.state.brand,"navbar-container-class":_vm.navbarContainerClass,"navbar-toggle-class":"sidebar-toggle-menu"}},[_vm._t("navbar-content")],2)]},proxy:true},{key:"sidebar",fn:function(){return [_c('app-sidebar',{staticClass:"sidebar-p-t",attrs:{"sidebar-search":false,"sidebar-search-form-class":"search-form--black","sidebar-brand":false,"sidebar-type":_vm.sidebarType,"sidebar-variant":_vm.sidebarVariant,"sidebar-menu":_vm.$store.getters['sidebarMenuConfig/layoutMenu']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var sidebar = ref.sidebar;
return [_vm._t("sidebar-content",null,{"sidebar":sidebar})]}}],null,true)})]},proxy:true},{key:"default",fn:function(ref){
var layoutProps = ref.layoutProps;
return [_c('router-view',{attrs:{"layout-props":layoutProps}})]}},{key:"footer-content",fn:function(footerProps){return [(_vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.SCHOOL)?_c('div',{staticClass:"text-70 school-footer",class:_vm.isMobSmallScreen ? 'mb-5' : ''},[_c('div',{class:_vm.isMobSmallScreen ? 'text-center' : 'd-flex align-items-center justify-content-center'},[(_vm.get(_vm.getCurrentSchool, 'phone'))?_c('div',{class:_vm.isMobSmallScreen ? 'mb-3' : 'mr-5'},[_c('md-icon',{staticClass:"mb-1 school-icon mr-1"},[_vm._v("local_phone")]),_c('a',{attrs:{"href":("tel:" + (_vm.get(_vm.getCurrentSchool, 'phone')))}},[_vm._v(_vm._s(_vm.get(_vm.getCurrentSchool, 'phone'))+" ")])],1):_vm._e(),(_vm.get(_vm.getCurrentSchool, 'contact_email'))?_c('div',{class:_vm.isMobSmallScreen ? 'mb-3' : 'mr-5'},[_c('md-icon',{staticClass:"mb-1 school-icon mr-1"},[_vm._v("email")]),_c('a',{attrs:{"href":("mailto:" + (_vm.get(_vm.getCurrentSchool, 'contact_email')))}},[_vm._v("Email")])],1):_vm._e(),(_vm.get(_vm.getCurrentSchool, 'website_url'))?_c('div',[_c('md-icon',{staticClass:"mb-1 school-icon mr-1"},[_vm._v("language")]),_c('a',{attrs:{"target":"_blank","href":_vm.get(_vm.getCurrentSchool, 'website_url')}},[_vm._v("Our Website")])],1):_vm._e()]),_c('div',{staticClass:"mt-4 text-center"},[_c('a',{attrs:{"href":("http://maps.google.com/?q=1200" + (_vm.get(_vm.getCurrentSchool, 'name')) + ", " + (_vm.get(
            _vm.getCurrentSchool,
            'address'
          )) + ", " + (_vm.get(_vm.getCurrentSchool, 'city')) + ", " + (_vm.get(_vm.getCurrentSchool, 'state'))),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.get(_vm.getCurrentSchool, 'name'))+" ")])]),(
          _vm.get(_vm.getCurrentSchool, 'address') &&
          _vm.get(_vm.getCurrentSchool, 'city') &&
          _vm.get(_vm.getCurrentSchool, 'state') &&
          _vm.get(_vm.getCurrentSchool, 'zipcode') &&
          _vm.get(_vm.getCurrentSchool, 'country')
        )?_c('div',{staticClass:"mt-2 text-center"},[_c('md-icon',{staticClass:"mb-1 school-icon"},[_vm._v("location_on")]),_c('a',{attrs:{"href":("http://maps.google.com/?q=1200" + (_vm.get(_vm.getCurrentSchool, 'name')) + ", " + (_vm.get(
            _vm.getCurrentSchool,
            'address'
          )) + ", " + (_vm.get(_vm.getCurrentSchool, 'city')) + ", " + (_vm.get(_vm.getCurrentSchool, 'state'))),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.get(_vm.getCurrentSchool, 'address'))+", "+_vm._s(_vm.get(_vm.getCurrentSchool, 'city'))+", "+_vm._s(_vm.get(_vm.getCurrentSchool, 'state'))+" "+_vm._s(_vm.get(_vm.getCurrentSchool, 'zipcode'))+", "+_vm._s(_vm.get(_vm.getCurrentSchool, 'country'))+" ")])],1):_vm._e(),_c('div',{staticClass:"mt-5 text-center"},[_c('strong',{staticClass:"small mr-2"},[_vm._v("POWERED BY")]),_c('img',{staticClass:"brand-icon",attrs:{"src":require("@/assets/images/logos/transition_connect_tp.png"),"width":"150","alt":footerProps.footerBrand}})])]):_c('div',[_c('p',{staticClass:"text-70 brand mb-24pt"},[_c('strong',{staticClass:"text-50 small mr-2"},[_vm._v("POWERED BY")]),_c('img',{staticClass:"brand-icon",attrs:{"src":require("@/assets/images/logos/logo_blue.png"),"width":"150","height":"auto","alt":footerProps.footerBrand}}),(
            !_vm.isMobSmallScreen &&
            _vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.EMPLOYER &&
            _vm.get(_vm.getLoggedInUser.linked_entity, 'organization.logo_url')
          )?_c('span',{staticClass:"ml-auto"},[_c('strong',{staticClass:"text-50 small mr-2"},[_vm._v(" PARTNERED WITH ")]),_c('img',{staticStyle:{"width":"auto","height":"60px"},attrs:{"src":_vm.get(_vm.getLoggedInUser.linked_entity, 'organization.logo_url'),"alt":""}})]):_vm._e()]),(
          _vm.isMobSmallScreen &&
          _vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.EMPLOYER &&
          _vm.get(_vm.getLoggedInUser.linked_entity, 'organization.logo_url')
        )?_c('div',{staticClass:"mb-2"},[_c('strong',{staticClass:"text-50 small ml-auto mr-2"},[_vm._v(" PARTNERED WITH ")]),_c('img',{staticStyle:{"width":"auto","height":"50px"},attrs:{"src":_vm.get(_vm.getLoggedInUser.linked_entity, 'organization.logo_url'),"alt":""}})]):_vm._e(),_c('p',{staticClass:"mb-8pt d-flex"},[_c('a',{staticClass:"text-70 text-underline mr-8pt small"},[_vm._v("Terms")]),_c('a',{staticClass:"text-70 text-underline small"},[_vm._v("Privacy")])]),_c('p',{staticClass:"text-50 measure-lead-max small"},[_vm._v(" Copyright "+_vm._s(footerProps.copyrightYear)+" © All rights reserved! ")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }