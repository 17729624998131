<template>
  <div id="app">
    <component :is="layout" v-if="layout" />
  </div>
</template>

<script>
const { name, version } = require('~/package.json');
import { Session } from 'fm-app-settings';
import { mapGetters, mapActions } from 'vuex';
import { get, isEmpty } from 'lodash';
import { DEFAULT_FAVICON, USER_ROLE_TYPES, DASHBOARD_TYPES } from './common/constants';
import App from './layouts/app.vue';
import Sticky from './layouts/sticky.vue';
import Boxed from './layouts/boxed.vue';
import Fixed from './layouts/fixed.vue';
import Blank from './layouts/blank.vue';

export default {
  head() {
    return {
      title: this.$store.state && this.$store.state.brand,
      htmlAttrs: {
        dir: `${this.$store.state && this.$store.getters.settings.layout.rtl ? 'rtl' : 'ltr'}`,
      },
    };
  },
  components: {
    App,
    Sticky,
    Boxed,
    Blank,
    Fixed,
  },
  data() {
    return {
      session: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getLoggedInUserDashboard']),
    layout() {
      return this.$store.getters.settings.layout.layout;
    },
  },
  methods: {
    ...mapActions('school', ['getSchoolFromCurrentDomain']),
    ...mapActions('navbarConfig', ['updateNavbarBrandDetails']),
    ...mapActions('student', ['getPiiStatus']),
  },
  async beforeMount() {
    this.session = new Session(`${name}-${version}`);

    if (
      get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.STUDENT &&
      this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL
    ) {
      // Get student PII status on every refresh.
      this.getPiiStatus();
    }

    if (
      (get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL ||
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.STUDENT ||
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.ORGANIZATION) &&
      this.getLoggedInUser.linked_entity
    ) {
      document.documentElement.style.setProperty('--primary-color', this.getLoggedInUser.linked_entity.primary_color);
      document.documentElement.style.setProperty(
        '--secondary-color',
        this.getLoggedInUser.linked_entity.secondary_color,
      );
      document.documentElement.style.setProperty('--dark-color', this.getLoggedInUser.linked_entity.dark_color);
      const favicon = document.getElementById('favicon');
      favicon.href = get(this.getLoggedInUser, 'linked_entity.small_logo_url') || DEFAULT_FAVICON;
    }

    if (
      get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER &&
      get(this.getLoggedInUser.linked_entity, 'organization')
    ) {
      document.documentElement.style.setProperty(
        '--primary-color',
        get(this.getLoggedInUser.linked_entity, 'organization.primary_color'),
      );
      document.documentElement.style.setProperty(
        '--secondary-color',
        get(this.getLoggedInUser.linked_entity, 'organization.secondary_color'),
      );
      document.documentElement.style.setProperty(
        '--dark-color',
        get(this.getLoggedInUser.linked_entity, 'organization.dark_color'),
      );
    }

    //refresh check
    try {
      const school = await this.getSchoolFromCurrentDomain({ storeSchool: true });
      if (!isEmpty(school)) {
        document.documentElement.style.setProperty('--primary-color', school.primary_color);
        document.documentElement.style.setProperty('--secondary-color', school.secondary_color);
        document.documentElement.style.setProperty('--dark-color', school.dark_color);
        const favicon = document.getElementById('favicon');
        favicon.href = get(school, 'small_logo_url') || DEFAULT_FAVICON;
        this.updateNavbarBrandDetails(school);
      }
    } catch (_err) {
      // Handle error if needed.
    }
  },
};
</script>
