import axios from 'axios';
import { get } from 'lodash';

import store from './store';
import router from './router';

// Setting axios default config.
const backendUrl = process.env.VUE_APP_BACKEND_BASE_URL;
axios.defaults.baseURL = backendUrl;

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.withCredentials = process.env.VUE_APP_AXIOS_CREDENTIALS === 'true';

axios.interceptors.request.use(function(request) {
  if (request.baseURL.includes(backendUrl) && store.getters['auth/isLoggedIn']) {
    const token = store.getters['auth/getAuthToken'];
    request.headers['Authorization'] = 'Bearer ' + token;
  }
  return request;
});

axios.interceptors.response.use(
  response => response,

  // Logging out user on 401.
  error => {
    if (
      get(error,'response.status') === 401 &&
      get(error, 'response.config.baseURL', '').includes(backendUrl) &&
      router.currentRoute.name !== 'login'
    ) {
      store.dispatch('auth/logout');
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  }
);

export default axios;
