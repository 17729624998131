import axios from 'axios';

const state = {
  simulationsFilters: {},
};

const getters = {
  getSimulationsFilters: state => state.simulationsFilters,
};

const mutations = {
  updateSimulationsFilters: (state, updatedFilters) => {
    state.simulationsFilters = { ...state.simulationsFilters, ...updatedFilters };
  },
};

const actions = {
  async getAllSimulations(_obj, params) {
    const res = await axios.get(`api/simulations/`, { params });
    return res.data;
  },

  async getEntitySimulations(_obj, params) {
    const res = await axios.get(`api/simulations/entity-simulations/`, { params });
    return res.data;
  },

  async getSimulation(_obj, id) {
    const res = await axios.get(`api/simulations/${id}/`);
    return res.data;
  },

  async getSimulationFromSlug(_obj, params) {
    const res = await axios.get(`api/simulations/from-slug/`, { params });
    return res.data;
  },

  async createSimulationFeedback(_obj, data) {
    return await axios.post(`api/simulations/call-logs/submit-feedback/`, data);
  },
  async createSimulation(_obj, data) {
    return axios.post(`api/simulations/`, data);
  },

  async registerSimulation(_obj, data) {
    return axios.post(`api/simulations/register-simulation/`, data);
  },

  async updateSimulation(_obj, { id, data }) {
    return axios.patch(`api/simulations/${id}/`, data);
  },

  async updateSimulationStatus(_obj, { id, data }) {
    return axios.patch(`api/simulations/${id}/update-is-active/`, data);
  },

  async deleteSimulation(_obj, id) {
    return axios.delete(`api/simulations/${id}/`);
  },

  async getSimulationImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/simulations/upload-url/`, data)).data.data;
  },

  async getSimulationsSubmissions(_obj, params) {
    const res = await axios.get(`api/simulations/call-logs/`, { params });
    return res.data;
  },

  async getSimulationSubmission(_obj, id) {
    const res = await axios.get(`api/simulations/call-logs/${id}/`);
    return res.data;
  },

  async getAiCallLogs(_obj, params) {
    const res = await axios.get(`api/simulations/ai-call-logs/`, { params });
    return res.data;
  },

  async getAiCallLog(_obj, id) {
    const res = await axios.get(`api/simulations/ai-call-logs/${id}/`);
    return res.data;
  },

  async updateSimulationsFilters({ commit }, { filters }) {
    commit('updateSimulationsFilters', filters);
  },
};

export const simulations = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
